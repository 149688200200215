import React, { useState, useEffect } from "react";
import './PedidoCon.css';
import {
  IonContent, IonGrid, IonRow, IonCol, IonToast, IonButton,
  IonIcon, IonModal, IonItem, IonLabel, IonToggle, IonBadge
} from '@ionic/react';
import {
  Drawer, Popover
} from '@material-ui/core';
import { addCircle, filterCircleOutline, newspaper, syncOutline } from 'ionicons/icons';
import ConfigProvider from '../../../assets/utils/configProvider'
import ajax from '../../../assets/utils/ajax';
import createFilterFieldAllTypes from '../../../assets/utils/filter/createFilterFieldAllTypes';
import Card from './card/Card';
import FilterContainer from '../../../components/filterContainer/filterContainer';
import FilterItem from '../../../components/filterContainer/filterItem';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Plugins } from '@capacitor/core';
import PedidoCad from './PedidoCad';
import PedidoRel from './PedidoRel';
import AnexoCon from './anexo/AnexoCon';
import Chat from './chat/Chat';
import PedidoFinalizar from './PedidoFinalizar';
import EscolherAprovador from './EscolherAprovador';
import { connect, useDispatch, useStore } from 'react-redux';
import { atualizarMsgs, createWebSocket, disconectedWebSocket, usuariosDigitacao } from "../../../assets/utils/redux/actions";
import globalVariables from '../../../assets/utils/globalVariables';
import LoadingGif from "../../../components/loadingGif/LoadingGif";

const conectaWebSocket = require("../../../assets/utils/conectaWebSocket");
const { SecureStoragePlugin } = Plugins;
const moment = require('moment');
const clearly602 = require("../../../assets/audios/clearly-602.ogg");
const audio = new Audio(clearly602);

let fieldsFilter = [{
  field: "id",
  type: "int",
  comparison: "eq",
  connector: "OR",
}, {
  field: "descricao",
  type: "string",
  comparison: "cn",
  connector: "OR",
}, {
  field: "observacao",
  type: "string",
  comparison: "cn",
  connector: "OR",
}, {
  grupo: [
    {
      field: "dataInicioDigitacao",
      type: "datetime",
      comparison: "le",
      connector: "AND"
    },
    {
      grupo: [
        {
          field: "dataTerminoDigitacao",
          type: "datetime",
          comparison: "ge",
          connector: "OR"
        },
        {
          field: "dataTerminoDigitacao",
          value: "null",
          type: "datetime",
          comparison: "eq",
          connector: "OR"
        }
      ],
      connector: "AND"
    }
  ],
  connector: "OR"
}, {
  field: "dataPedido",
  type: "date",
  comparison: "eq",
  connector: "OR"
}];

// let page = 1;
// let start = 0;
const msgLoadingDeafult = "Carregando pedidos extras...";

interface PedidoExtraReg {
  id: any;
  idEmpresa?: any;
  idPedidoExtra?: any;
  abrirTelaAprovacao?: Boolean;
  idUsuarioAprovacao?: any;
  idUsuarioPortalPedido?: any;
  tipoFinalidade?: any;
  finalizaPedido?: Boolean;
  mensagensNovas?: any;
}

interface PedidoExtraChatReg {
  id: any;
  descricao: any;
  pedidoDisabled: any
}

interface TipoPedidoExtraReg {
  id: any;
  descricao: any;
  finalidade: any
}

interface TipoPedidoExtraChecked {
  id: any;
  value: any;
}

interface PopoverVirtualElement {
  nodeType: 1;
  getBoundingClientRect: () => DOMRect;
}

interface filtroReg {
  checkedCompra: any;
  checkedCotacao: any;
  checkedVoceCriou: any;
  checkedCompradorCriou: any;
  checkedCooperativaCriou: any;
}

let arrDataByPage: any = {};
let pageAux = 0;

const PedidoCon: React.FC = (props: any) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showPopover, setShowPopover] = useState(false);
  const [showModalRelatorio, setShowModalRelatorio] = useState(false);
  const [showModalAnexo, setShowModalAnexo] = useState(false);
  const [showPopoverAprovar, setShowPopoverAprovar] = useState(false);
  const [showPopoverPedirAprovador, setShowPopoverPedirAprovador] = useState(false);
  const [showPopoverReprovar, setShowPopoverReprovar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalChat, setShowModalChat] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [alteraSomenteObservacao, setAlteraSomenteObservacao] = useState(false);
  const [checkedFiltro, setCheckedFiltro] = useState<filtroReg>({
    checkedCompra: false,
    checkedCotacao: false,
    checkedVoceCriou: false,
    checkedCompradorCriou: false,
    checkedCooperativaCriou: false
  });
  const [checkedFiltroTipo, setCheckedFiltroTipo] = useState<any>();
  const [pedidoChat, setPedidoChat] = useState<PedidoExtraChatReg>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [showPopoverIgnorar, setShowPopoverIgnorar] = useState(false);
  const [idUsuarioPortal, setIdUsuarioPortal] = useState<number>();
  const [pedidoSelecionado, setPedidoSelecionado] = useState<PedidoExtraReg>();
  const [ordened, setOrdened] = useState(false);
  const [showSelectedOptionsBar, setShowSelectedOptionsBar] = useState(false);
  const [arrData, setArrData] = useState<any>([]);
  const [topTabFilters, setTopTabFilters] = useState(0);
  const [bottomTabFilters, setBottomTabFilters] = useState(0);
  const [tiposPedidosList, setTiposPedidosList] = useState<[TipoPedidoExtraReg]>();
  const [pedeAprovacao, setPedeAprovacao] = useState<boolean>(false);
  const [pedeAprovador, setPedeAprovador] = useState<boolean>(false);
  const [buscouPedeAprovacao, setBuscouPedeAprovacao] = useState<boolean>(false);
  const [qtnsFiltros, setQtnsFiltros] = useState({
    total: 0,
    pendentes: 0,
    andamentos: 0,
    concluidos: 0,
    ignorados: 0
  });

  const dispatch = useDispatch();
  const store = useStore();
  let totalFiltrosAux = 0;
  let limit = 11;

  useEffect(() => {
    if (showModal == true) {
      window.location.hash = "#pedidoModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModal])

  useEffect(() => {
    if (showModalChat == true) {
      window.location.hash = "#chatModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModalChat])

  useEffect(() => {
    if (showModalRelatorio == true) {
      window.location.hash = "#pedidoRelatorioModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModalRelatorio])

  useEffect(() => {
    arrDataByPage = {};
    loadPedidos();
    if (!buscouPedeAprovacao) buscaPedeAprovacaoAprovador();
  }, [props.valueSearch, topTabFilters, bottomTabFilters, checkedFiltro, checkedFiltroTipo]);

  // usado pelo redux
  useEffect(() => {
    loadPedidos(true, true, pageAux);
  }, [props.atualizarMsgs]);

  useEffect(() => {
    SecureStoragePlugin.get({ key: 'checkedFiltro' })
      .then((checked: any) => {
        if (checked.value != JSON.stringify(checkedFiltro)) {
          setCheckedFiltro(JSON.parse(checked.value));
        }
      })
      .catch((error: any) => {
        console.log("Erro ao recuperar valor da seção. Erro: " + error);
      });

    SecureStoragePlugin.get({ key: 'checkedFiltroTipo' })
      .then((value: any) => {
        if (value.value != JSON.stringify(checkedFiltroTipo)) {
          setCheckedFiltroTipo(JSON.parse(value.value));
        }
      })
      .catch((error: any) => {
        console.log("Erro ao recuperar valor da seção. Erro: " + error);
      });

  }, [props.valueSearch, topTabFilters, bottomTabFilters])

  SecureStoragePlugin.get({ key: 'idUsuario' })
    .then((idUsuario: any) => {
      setIdUsuarioPortal(parseInt(idUsuario.value));
    })
    .catch((error: any) => {
      setIdUsuarioPortal(-1);
    });

  const buscaPedeAprovacaoAprovador = () => {
    setBuscouPedeAprovacao(true);

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/exists/pedeAprovacaoAprovador',
      data: {
        registro: {
          id: null
        }
      },
      fnSetShowLoading: setShowLoading,
      beforeMsgSuccessTrue: function (response: any) {
        if (response.registro && response.registro.pedidoExtraGrupo != null) {
          // aqui chamar a tela de pedir aprovação
          if (response.registro.pedidoExtraGrupo.pedeAprovacao != null && response.registro.pedidoExtraGrupo.pedeAprovacao == 1) {
            setPedeAprovacao(true);
          } else {
            setPedeAprovacao(false);
          }

          if (response.registro.pedidoExtraGrupo.pedeAprovador != null && response.registro.pedidoExtraGrupo.pedeAprovador == 1) {
            setPedeAprovador(true);
          } else {
            setPedeAprovador(false);
          }

        }

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setPedeAprovacao(false);
        setPedeAprovador(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgFailure: function (response: any) {
        setPedeAprovacao(false);
        setPedeAprovador(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const loadPedidos = (naoMostraGif?: boolean, naoMostrarMsgErro?: boolean, page?: any, callback?: any) => {
    if (page == null || page == undefined || page == 0) page = 1;
    limit = (page * limit);
    let start = (page * limit) - limit;

    let filterAva: any = [];
    if (props.valueSearch && props.valueSearch !== undefined) {

      let filterFields = new Array();
      createFilterFieldAllTypes(props.valueSearch, fieldsFilter, filterFields, "cn");
      if (filterFields.length > 0) {
        filterAva.push({
          grupo: filterFields,
          connector: "AND"
        });
        page = 1;
        start = 0;
      }
    }

    const dataAtual = moment().format("YYYY-MM-DD HH:mm:ss");

    var filtrosAux: any = [];
    if (bottomTabFilters === 0) {
      filtrosAux.push({
        grupo: [{
          field: "situacao",
          value: 2,
          type: "int",
          comparison: "eq",
          connector: "AND"
        },
        {
          field: "dataInicioDigitacao",
          value: dataAtual,
          type: "datetime",
          comparison: "le",
          connector: "AND"
        },
        {
          grupo: [
            {
              field: "dataTerminoDigitacao",
              value: dataAtual,
              type: "datetime",
              comparison: "ge",
              connector: "OR"
            },
            {
              field: "dataTerminoDigitacao",
              value: "null",
              type: "datetime",
              comparison: "eq",
              connector: "OR"
            }
          ],
          connector: "AND"
        }
        ],
        connector: "AND"
      });
    }
    else if (bottomTabFilters === 2) {
      filtrosAux.push({
        grupo: [{
          field: "situacao",
          value: "3,4,7,9",
          type: "int",
          comparison: "in",
          connector: "AND"
        },
        {
          field: "dataInicioDigitacao",
          value: dataAtual,
          type: "datetime",
          comparison: "le",
          connector: "AND"
        },
        {
          grupo: [
            {
              field: "dataTerminoDigitacao",
              value: dataAtual,
              type: "datetime",
              comparison: "ge",
              connector: "OR"
            },
            {
              field: "dataTerminoDigitacao",
              value: "null",
              type: "datetime",
              comparison: "eq",
              connector: "OR"
            }
          ],
          connector: "AND"
        }
        ],
        connector: "AND"
      });
    }
    else if (bottomTabFilters === 1) {
      filtrosAux.push({
        grupo: [
          {
            field: "situacao",
            value: "5,6,8",
            type: "int",
            comparison: "in",
            connector: "OR"
          },
          {
            field: "dataTerminoDigitacao",
            value: dataAtual,
            type: "datetime",
            comparison: "le",
            connector: "OR"
          }
        ],
        connector: "AND"
      });
    }

    if (topTabFilters != 0) {
      filtrosAux.push({
        field: "statusPedido",
        value: topTabFilters,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    if (!checkedFiltro.checkedCompra || !checkedFiltro.checkedCotacao) {
      if (checkedFiltro.checkedCompra) {
        filtrosAux.push({
          field: "tipoFinalidade",
          value: 1,
          type: "int",
          comparison: "eq",
          connector: "AND"
        });
      }
      if (checkedFiltro.checkedCotacao) {
        filtrosAux.push({
          field: "tipoFinalidade",
          value: 2,
          type: "int",
          comparison: "eq",
          connector: "AND"
        });
      }
    }

    if (checkedFiltro.checkedVoceCriou) {
      filtrosAux.push({
        field: "voceCriou",
        value: 1,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    if (checkedFiltro.checkedCompradorCriou) {
      filtrosAux.push({
        field: "compradorCriou",
        value: 1,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    if (checkedFiltro.checkedCooperativaCriou) {
      filtrosAux.push({
        field: "cooperativaCriou",
        value: 1,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    if (checkedFiltroTipo) {
      const arrTipos = Object.keys(checkedFiltroTipo);

      let valueIn = "";
      for (const id of arrTipos) {
        if (checkedFiltroTipo[id]) {
          if (valueIn != "") valueIn += ",";
          valueIn += id;
        }
      }

      if (valueIn != undefined && valueIn != "") {
        filtrosAux.push({
          field: "idTipoPedido",
          value: valueIn,
          type: "int",
          comparison: "in",
          connector: "AND"
        });
      }
    }

    let order = [{ property: "dataTerminoDigitacao", direction: "ASC" }, { property: "id", direction: "DESC" }];
    if (bottomTabFilters === 2) {
      order = [{ property: "id", direction: "DESC" }];
    }
    else if (bottomTabFilters === 1) {
      order = [{ property: "id", direction: "DESC" }];
    }
    
    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUsuario: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/consulta/load/app',
          data: {
            page: page,
            start: 0,
            limit: limit,
            filterAva: filterAva,
            filterFix: filtrosAux,
            sort: order,
          },
          noMsg: naoMostrarMsgErro,
          fnSetShowLoading: naoMostraGif ? null : setShowLoading,
          fnSetShowAlert: (param: any) => setShowAlert(param),
          beforeMsgSuccessTrue: function (response: any) {

            if (callback) callback();

            // guardar os registros por pagina
            // arrDataByPage[page] = response.registros;
            // let arr:any = [];
            // for (const property in arrDataByPage) {
            //   arr = arr.concat(arrDataByPage[property]);
            // }
            // setArrData(arr);
            setArrData(response.registros);

            pageAux = page;

            setQtnsFiltros({
              total: response.total ? response.total : 0,
              pendentes: response.totalPendentes,
              andamentos: response.totalAndamentos,
              concluidos: response.totalConcluidos,
              ignorados: response.totalIgnorados ? response.totalIgnorados : 0
            });

            if (!store.getState().createWebSocket.created) {
              try {
                const baseUrl = new ConfigProvider().getConfig("baseURLG3WSCotacao");
                var baseUrlWebsocket = baseUrl.replace("http", "ws");
                var arrUrl = baseUrlWebsocket.split("/");
                baseUrlWebsocket = arrUrl[0] + "//" + arrUrl[2];

                SecureStoragePlugin.get({ key: 'token' })
                  .then((token: any) => {
                    for (let reg of response.registros) {
                      conectaWebSocket.conn(globalVariables, token.value, baseUrlWebsocket + '/compras/websocket/pedidos/extras/chat/' + (reg.id + "-" + idUsuario.value),
                        () => { },
                        (event: any) => callbackOnMessage(event, idUsuario),
                        onCallbackOnCloseError);
                    }
                  })
                  .catch((error: any) => null);
              }
              catch (exception) {
                console.log("Erro ao se conectar ao websocket de chat. Erro: " + exception);
              }
            }

            if (response.responseCallback) response.responseCallback();
          },
          beforeMsgSuccessFalse: function (response: any) {
            if (callback) callback();
          }
        });
      })
      .catch((error: any) => {
        if (callback) callback();
        console.log("Erro: " + error);
        return null
      });
  }

  const callbackOnMessage = (event: any, idUsuario: any) => {
    dispatch(createWebSocket(true));
    dispatch(disconectedWebSocket(false));

    try {
      const data = JSON.parse(event.data);
      if (data.mensagem) {
        if (data.mensagem == "digitandoMsg") {
          let usuariosDigitacaoAux = store.getState().usuariosDigitacao.usuarios;
          let arrayCopy = [...usuariosDigitacaoAux];
          if (arrayCopy != null) {
            const obj = { idPedidoExtra: data.idPedidoExtra, usuario: data.nomeUsuario };
            const pos = arrayCopy.findIndex(object => {
              return object.idPedidoExtra === obj.idPedidoExtra && object.usuario === obj.usuario;
            });
            if (pos === -1 && data.idUsuarioPortal != idUsuario.value) {
              arrayCopy.push(obj);
            }
          }
          dispatch(usuariosDigitacao(arrayCopy));
        }
        else if (data.mensagem == "parouDigitacaoMsg") {
          let usuariosDigitacaoAux = store.getState().usuariosDigitacao.usuarios;
          let arrayCopy = [...usuariosDigitacaoAux];
          if (arrayCopy != null) {
            const obj = { idPedidoExtra: data.idPedidoExtra, usuario: data.nomeUsuario };
            const pos = arrayCopy.findIndex(object => {
              return object.idPedidoExtra === obj.idPedidoExtra && object.usuario === obj.usuario;
            });
            if (pos !== -1) {
              arrayCopy = arrayCopy.slice(pos + 1);
            }
          }
          dispatch(usuariosDigitacao(arrayCopy));
        }
        else {
          const countAtual = store.getState().clickState.atualizarMsgs;
          dispatch(atualizarMsgs(data.mensagem, countAtual + 1, data.idUsuarioPortal, data.nomeUsuario, data.registroSend));

          if (data.mensagem == "saveMsg" && data.idUsuarioPortal != idUsuario.value) {
            loadPedidos(true, true, pageAux);

            audio.play()
              .then(() => {
              })
              .catch((error: any) => {
                console.log(error);

                setShowAlert({
                  isOpen: true,
                  color: 'warning',
                  header: 'Aviso',
                  message: "Confirme está mensagem para receber notifacações sonoras de novas mensagens do chat!",
                  buttons: ['OK'],
                  onDidDismiss: () => {
                    setShowAlert({ isOpen: false });
                  }
                });
              })
          }
        }
      }
    }
    catch (exception) {
    }
  }

  const onCallbackOnCloseError = (event: any, error: any) => {
    dispatch(disconectedWebSocket(true));
  }

  const onRemoverPedido = () => {
    if (pedidoSelecionado == null || pedidoSelecionado.id === null) return;

    setShowLoadingMsg("Removendo pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/delete/app',
      data: {
        registros: [{
          id: pedidoSelecionado.id
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Pedido extra removido com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        // let page = pageAux;
        // for (const property in arrDataByPage) {
        //   for (let regPage of arrDataByPage[property]) {
        //     if (regPage.id == pedidoSelecionado.id) {
        //       console.log(regPage.id);
        //       console.log(property);
        //       page = parseInt(property);
        //       break;
        //     }
        //   }
        // }

        loadPedidos(true, true, pageAux, () => {
          // console.log(page);
          // console.log(arrData.length);
          // if (arrData.length/limit > page) {
          //   loadPedidos(true, true, page+1);
          // }
        });

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onIgnorarPedidos = () => {
    setShowLoadingMsg("Ignorando pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/usuarios/empresas/cadastro/ignora',
      data: {
        registro: pedidoSelecionado
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        loadPedidos(true, true, pageAux);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onRestabelecerPedido = (pedido: object) => {
    setShowLoadingMsg("Restabelecendo pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/usuarios/empresas/cadastro/restabelece',
      data: {
        registro: pedido
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        loadPedidos(true, true, pageAux);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onAprovarCompraPedido = () => {
    setShowLoadingMsg("Aprovando compra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/aprovaCompra/app',
      data: {
        registro: pedidoSelecionado
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        loadPedidos(true, true, pageAux);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onReprovarCompraPedido = () => {
    setShowLoadingMsg("Reprovando compra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/reprovaCompra/app',
      data: {
        registro: pedidoSelecionado
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        loadPedidos(true, true, pageAux);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onReenviaNotificacaoComprador = (idPedidoExtra: any) => {
    setShowLoadingMsg("Reenviando notificação...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/reenviar/notificacao/novo/pedido/app',
      data: {
        registro: {
          id: idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onDuplicarPedido = (idPedidoExtra: any) => {
    setShowLoadingMsg("Duplicando...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/duplicar/app',
      data: {
        registro: {
          id: idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);

        setPedidoSelecionado(response?.registro);
        setShowModal(true);

        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Pedido extra duplicado com sucesso! Código: " + response?.registro?.id,
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const buscaTiposPedidos = () => {
    setShowLoadingMsg("Buscando tipos de pedidos...");

    try {
      ajax({
        url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/tipos/consulta/load',
        data: {
          page: 1,
          start: 0,
          limit: 10,
          filterAva: [],
          filterFix: [],
          sort: [{ property: "descricao", direction: "ASC" }],
        },
        fnSetShowLoading: setShowLoading,
        fnSetShowAlert: setShowAlert,
        beforeMsgSuccessTrue: function (response: any) {
          setShowLoadingMsg(msgLoadingDeafult);
          setTiposPedidosList(response.registros);

          if (response.registros != undefined && response.registros != null && response.registros.length > 0) {
            const opts = { ...checkedFiltroTipo };
            for (const tipoChecked of response.registros) {
              if (checkedFiltroTipo && !checkedFiltroTipo.hasOwnProperty(tipoChecked.id)) {
                opts[tipoChecked.id] = false;
              }
            }
            setCheckedFiltroTipo(opts);
          }

          setOpenDrawer(!openDrawer)
        },
        beforeMsgSuccessFalse: function (response: any) {
          setShowLoadingMsg(msgLoadingDeafult);
          setOpenDrawer(!openDrawer)
        },
        beforeMsgSuccessFailure: function (response: any) {
          setShowLoadingMsg(msgLoadingDeafult);
          setOpenDrawer(!openDrawer)
        }
      });
    }
    catch (exception) {
      setShowLoadingMsg(msgLoadingDeafult);
      setOpenDrawer(!openDrawer)
    }
  }

  const onFinalizarPedido = (idPedidoExtra: any) => {
    setShowLoadingMsg("Finalizando pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/usuarios/empresas/cadastro/finaliza',
      data: {
        registro: {
          idPedidoExtra: idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        loadPedidos(true, false, pageAux);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onChangeFiltroCompra = (e: any) => {
    let reg = { ...checkedFiltro, checkedCompra: e.detail.checked };
    setCheckedFiltro(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltro" });
    SecureStoragePlugin.set({ key: "checkedFiltro", value: JSON.stringify(reg) });
  }

  const onChangeFiltroCotacao = (e: any) => {
    let reg = { ...checkedFiltro, checkedCotacao: e.detail.checked };
    setCheckedFiltro(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltro" });
    SecureStoragePlugin.set({ key: "checkedFiltro", value: JSON.stringify(reg) });
  }

  const onChangeFiltroVoceCriou = (e: any) => {
    let reg = { ...checkedFiltro, checkedVoceCriou: e.detail.checked };
    setCheckedFiltro(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltro" });
    SecureStoragePlugin.set({ key: "checkedFiltro", value: JSON.stringify(reg) });
  }

  const onChangeFiltroCompradorCriou = (e: any) => {
    let reg = { ...checkedFiltro, checkedCompradorCriou: e.detail.checked };
    setCheckedFiltro(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltro" });
    SecureStoragePlugin.set({ key: "checkedFiltro", value: JSON.stringify(reg) });
  }

  const onChangeFiltroCooperativaCriou = (e: any) => {
    let reg = { ...checkedFiltro, checkedCooperativaCriou: e.detail.checked };
    setCheckedFiltro(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltro" });
    SecureStoragePlugin.set({ key: "checkedFiltro", value: JSON.stringify(reg) });
  }

  const onChangeFiltroTipoPedido = (e: any, idTipoPedido: any) => {
    let reg = { ...checkedFiltroTipo };
    reg[idTipoPedido] = e.detail.checked;
    setCheckedFiltroTipo(reg);

    SecureStoragePlugin.remove({ key: "checkedFiltroTipo" });
    SecureStoragePlugin.set({ key: "checkedFiltroTipo", value: JSON.stringify(reg) });
  }

  const mergeArrayObjects = (start: any, arrAtual: any, arrNovo: any) => {
    for (let i = (start); i < arrAtual.length;) {
      let achou = false;
      for (const item2 of arrNovo) {
        if (arrAtual[i].id === item2.id) {
          achou = true;
          //merging two objects
          arrAtual[i] = Object.assign({}, arrAtual[i], item2);
        }
      }

      if (!achou) {
        arrAtual.splice(i, 1);
      }

      i++;
    }
    return arrAtual;
  }

  const filtroFinalidade = <>
    <div style={{ float: "left" }}>
      <IonItem>
        <IonToggle color="primary" checked={checkedFiltro.checkedCompra} onIonChange={onChangeFiltroCompra} />
        <IonLabel style={{ color: "black" }}>Compra</IonLabel>
      </IonItem>
    </div>

    <div style={{ float: "left" }}>
      <IonItem>
        <IonToggle color="primary" checked={checkedFiltro.checkedCotacao} onIonChange={onChangeFiltroCotacao} />
        <IonLabel style={{ color: "black" }}>Cotação</IonLabel>
      </IonItem>
    </div>

    <div style={{ float: "left" }}>
      <IonItem>
        <IonToggle color="primary" checked={checkedFiltro.checkedVoceCriou} onIonChange={onChangeFiltroVoceCriou} />
        <IonLabel style={{ color: "black" }}>Você criou</IonLabel>
      </IonItem>
    </div>

    <div style={{ float: "left" }}>
      <IonItem>
        <IonToggle color="primary" checked={checkedFiltro.checkedCompradorCriou} onIonChange={onChangeFiltroCompradorCriou} />
        <IonLabel style={{ color: "black" }}>Comprador criou</IonLabel>
      </IonItem>
    </div>

    <div style={{ float: "left" }}>
      <IonItem>
        <IonToggle color="primary" checked={checkedFiltro.checkedCooperativaCriou} onIonChange={onChangeFiltroCooperativaCriou} />
        <IonLabel style={{ color: "black" }}>Portal criou</IonLabel>
      </IonItem>
    </div>

    {tiposPedidosList != null && tiposPedidosList.length > 0 ? <div style={{ float: "left" }}>
      <IonItem>
        <IonLabel style={{ color: "black" }}>Tipo do Pedido:</IonLabel>
      </IonItem>
    </div> : ""}

    {tiposPedidosList != null && tiposPedidosList.length > 0 ? tiposPedidosList.map((tipo: any) => {
      return <div key={tipo.id} style={{ float: "left" }}>
        <IonItem key={tipo.id}>
          <IonToggle color="primary" checked={checkedFiltroTipo != undefined ? (checkedFiltroTipo[tipo.id]) : false}
            onIonChange={(e) => onChangeFiltroTipoPedido(e, tipo.id)} />
          <IonLabel style={{ color: "black" }}>{tipo.descricao}</IonLabel>
        </IonItem>
      </div>
    }) : ""}
  </>

  if (checkedFiltro.checkedCompra) totalFiltrosAux++;
  if (checkedFiltro.checkedCotacao) totalFiltrosAux++;
  if (checkedFiltro.checkedVoceCriou) totalFiltrosAux++;
  if (checkedFiltro.checkedCompradorCriou) totalFiltrosAux++;
  if (checkedFiltro.checkedCooperativaCriou) totalFiltrosAux++;
  if (checkedFiltroTipo != null && checkedFiltroTipo != undefined) {
    const arrTipos = Object.keys(checkedFiltroTipo);
    for (const id of arrTipos) {
      if (checkedFiltroTipo[id]) {
        totalFiltrosAux++;
      }
    }
  }

  const buttonFiltro = <div style={{ float: "left" }}>
    <IonButton
      className='btn-novo-pedido'
      onClick={() => buscaTiposPedidos()}
    >
      FILTROS
      <IonIcon slot="start" size='large' icon={filterCircleOutline} />
    </IonButton>

    {totalFiltrosAux > 0 ? <IonBadge
      onClick={() => { }}
      className="badge-btn-notification-filtros"
      color="success">
      {totalFiltrosAux}
    </IonBadge>
      : ""}
    &nbsp;&nbsp;
    <IonButton
      className='btn-novo-pedido'
      onClick={() => {
        loadPedidos(false, false, null, () => {
          buscaPedeAprovacaoAprovador();
        });
      }}
    >
      ATUALIZAR
      <IonIcon slot="start" size='large' icon={syncOutline} />
    </IonButton>
  </div>

  return (
    <IonContent className="ionContentViewport">

      <LoadingGif show={showLoading} msg={showLoadingMsg} />

      <Popover
        open={showPopoverIgnorar}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopoverIgnorar(false)
        }}
      >
        <div className="myPopover">
          <p>Deseja realmente ignorar pedido extra?</p>
          <IonButton
            color="success"
            onClick={() => {
              setShowPopoverIgnorar(false);
              onIgnorarPedidos();
            }}
            size="small"
          >
            ignorar
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => setShowPopoverIgnorar(false)}
            size="small"
          >
            Cancelar
          </IonButton>
        </div>
      </Popover>

      <Popover
        open={showPopoverAprovar}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopoverAprovar(false)
        }}
      >
        <div className="myPopover">
          <p>Deseja realmente aprovar este pedido extra para compra?</p>
          <IonButton
            color="success"
            onClick={() => {
              setShowPopoverAprovar(false);
              onAprovarCompraPedido();
            }}
            size="small"
          >
            Sim
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => setShowPopoverAprovar(false)}
            size="small"
          >
            Não
          </IonButton>
        </div>
      </Popover>

      {pedidoSelecionado != null && pedidoSelecionado.abrirTelaAprovacao && !pedidoSelecionado.finalizaPedido ?
        <EscolherAprovador
          id={pedidoSelecionado.id}
          idUsuarioAprovacao={pedidoSelecionado.idUsuarioAprovacao}
          idUsuarioPortal={idUsuarioPortal}
          onCallback={() => loadPedidos(true, true, pageAux)}
          setPedidoSelecionado={setPedidoSelecionado}
          showPopoverPedirAprovador={showPopoverPedirAprovador}
          setShowPopoverPedirAprovador={setShowPopoverPedirAprovador}
          alteraAprovadorOnFinalizar={false}
        /> : ""}

      {pedidoSelecionado != null && pedidoSelecionado.abrirTelaAprovacao && pedidoSelecionado.finalizaPedido ? <PedidoFinalizar
        id={pedidoSelecionado.id}
        tipoFinalidade={pedidoSelecionado.tipoFinalidade}
        idUsuarioAprovacao={pedidoSelecionado.idUsuarioAprovacao}
        idUsuarioPortalLogado={idUsuarioPortal}
        idUsuarioPortalPedido={pedidoSelecionado.idUsuarioPortalPedido}
        setPedidoSelecionado={setPedidoSelecionado}
        onCallback={onFinalizarPedido} /> : ""}

      <Popover
        open={showPopoverReprovar}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopoverReprovar(false)
        }}
      >
        <div className="myPopover">
          <p>Deseja realmente reprovar este pedido extra para compra?</p>
          <IonButton
            color="success"
            onClick={() => {
              setShowPopoverReprovar(false);
              onReprovarCompraPedido();
            }}
            size="small"
          >
            Sim
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => setShowPopoverReprovar(false)}
            size="small"
          >
            Não
          </IonButton>
        </div>
      </Popover>

      <Popover
        open={showPopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        // mode="ios"
        onClose={e => {
          setShowPopover(false)
        }}
      >
        <div className="myPopover">
          <p>Deseja realmente remover este pedido extra?</p>
          <IonButton
            color="success"
            onClick={() => {
              onRemoverPedido();
              setShowPopover(false);
            }}
            size="small"
          >
            Remover
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => setShowPopover(false)}
            size="small"
          >
            Cancelar
          </IonButton>
        </div>
      </Popover>

      <IonGrid className="ionGridViewport">
        <IonRow>
          <IonCol className="ionColViewport">
            <FilterContainer justyfy={"space-around"}>
              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(0);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "0" });
                }}
                description={"Todos"}
                value={qtnsFiltros.total ? qtnsFiltros.total : 0}
                selected={topTabFilters == 0}
                colorBorderBottom={null}
                enableBorderBottom={true}
                tooltip={"Clique para mostrar todos os seus pedidos extras."}
              />
              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(1);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "1" });
                }}
                description={"Pendentes"}
                value={qtnsFiltros.pendentes ? qtnsFiltros.pendentes : 0}
                selected={topTabFilters == 1}
                colorBorderBottom={"#76DC7B"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras pendentes'}
              />

              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(2);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "2" });
                }}
                description={"Em Andamento"}
                value={qtnsFiltros.andamentos ? qtnsFiltros.andamentos : 0}
                selected={topTabFilters == 2}
                colorBorderBottom={"#599BFF"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras em andamento'}
              />

              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(3);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "3" });
                }}
                description={"Concluídos"}
                value={qtnsFiltros.concluidos ? qtnsFiltros.concluidos : 0}
                selected={topTabFilters == 3}
                colorBorderBottom={"#FF9248"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras concluídos'}
              />

              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(4);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "4" });
                }}
                description={"Ignorados"}
                value={qtnsFiltros.ignorados ? qtnsFiltros.ignorados : 0}
                selected={topTabFilters == 4}
                colorBorderBottom={"#c44c50"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras ignorados'}
              />
            </FilterContainer>
          </IonCol>
        </IonRow>
      </IonGrid>

      {bottomTabFilters != 1
        ? <div className="d-table w-100">
          <div className="d-table-cell tar">

            {buttonFiltro}

            <IonButton
              className='btn-novo-pedido'
              onClick={() => {
                setShowModalRelatorio(true)
              }}
            >
              RELATÓRIO
              <IonIcon style={{ fontSize: 24 }} slot="start" icon={newspaper} />
            </IonButton>

            <IonButton
              className='btn-novo-pedido'
              onClick={() => {
                SecureStoragePlugin.set({ key: "ultimaFilialProduto", value: "" });
                SecureStoragePlugin.set({ key: "ultimaDescricaoProduto", value: "" });

                setPedidoSelecionado({
                  id: null
                });
                setShowModal(true);
              }}
            >
              NOVO PEDIDO
              <IonIcon slot="start" size='large' icon={addCircle} />
            </IonButton>
          </div>
        </div>
        : <div className="d-table w-100">
          <div className="d-table-cell tar">
            {buttonFiltro}
          </div>
        </div>}

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {filtroFinalidade}
      </Drawer>

      <Card
        data={arrData}
        limit={limit}
        propsRouter={props.propsRouter}
        setValueSearch={props.setValueSearch}
        idUsuarioPortal={idUsuarioPortal}
        onSetShowPopoverIgnorar={() => setShowPopoverIgnorar(true)}
        onSetPedidoSelecionado={setPedidoSelecionado}
        onSetShowPopover={setShowPopover}
        onSetShowModal={setShowModal}
        onSetShowModalChat={setShowModalChat}
        onSetShowModalAnexo={setShowModalAnexo}
        onSetPedidoChat={setPedidoChat}
        onSetShowPopoverAprovar={setShowPopoverAprovar}
        onSetShowPopoverReprovar={setShowPopoverReprovar}
        onSetShowPopoverPedirAprovador={setShowPopoverPedirAprovador}
        onSetAlteraSomenteObservacao={setAlteraSomenteObservacao}
        onReenviaNotificacaoComprador={onReenviaNotificacaoComprador}
        onDuplicarPedido={onDuplicarPedido}
        onRestabelecerPedido={onRestabelecerPedido}
        onFinish={loadPedidos}
        onFinalizarPedido={onFinalizarPedido}
        pedeAprovacao={pedeAprovacao}
        pedeAprovador={pedeAprovador}
      />

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModal}
        onDidDismiss={() => {
          setShowModal(false)
          loadPedidos(true, true, pageAux);
        }}
      >
        <PedidoCad
          idPedidoExtra={pedidoSelecionado != null ? pedidoSelecionado.id : null}
          idUsuarioPortal={idUsuarioPortal}
          valueSearch={props.valueSearch}
          propsRouter={props.router}
          alteraSomenteObservacao={alteraSomenteObservacao}
          novasMensagens={pedidoSelecionado != null ? pedidoSelecionado.mensagensNovas : 0}
          onSetShowModal={(cond: boolean, refresh: boolean) => {
            if (!cond) {
              loadPedidos(true, true, pageAux);
            }
            setShowModal(cond);
          }}
        />
      </IonModal>

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModalAnexo}
        onDidDismiss={() => setShowModalAnexo(false)}
      >
        {showModalAnexo ?
          <AnexoCon
            idPedidoExtra={pedidoSelecionado != null ? pedidoSelecionado.id : null}
            idUsuarioPortal={idUsuarioPortal}
            propsRouter={props.propsRouter}
            onSetShowModalAnexo={(cond: boolean, refresh: boolean) => {
              setShowModalAnexo(cond);
            }}
          /> : ""}
      </IonModal>

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModalChat}
        onDidDismiss={() => {
          setShowModalChat(false)
        }}
      >
        {showModalChat && pedidoChat ?
          <Chat
            idPedidoExtra={pedidoChat.id}
            descricaoPedido={pedidoChat.descricao}
            pedidoDisabled={pedidoChat.pedidoDisabled}
            idUsuarioPortal={idUsuarioPortal}
            propsRouter={props.propsRouter}
            onSetShowModalChat={(cond: boolean, refresh: boolean) => {
              setShowModalChat(cond);
            }}
          /> : ""}
      </IonModal>

      <IonModal
        cssClass="modalProCad"
        isOpen={showModalRelatorio}
        swipeToClose={true}
        onDidDismiss={() => {
          setShowModalRelatorio(false)
        }}
      >
        {showModalRelatorio ? <PedidoRel setAuxShowModalRelatorio={setShowModalRelatorio} /> : ""}
      </IonModal>

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />

      <div style={{ marginBottom: "100px" }} />
      <div style={{ zIndex: 2, position: "fixed", bottom: "0px", width: "100%", textAlign: "center" }} hidden={ordened || showSelectedOptionsBar}>
        <FilterContainer justyfy={"space-around"} >
          <FilterItem
            iconType
            onClick={() => {
              setBottomTabFilters(0);
              SecureStoragePlugin.set({ key: "bottomBarFilter", value: "0" });
            }}
            description={"Abertos"}
            selected={bottomTabFilters == 0}
            colorBorderBottom={null}
            enableBorderBottom={false}
            icon={<ListAltIcon />}
            tooltip={'Clique para mostrar as pedidos extras que estão disponíveis.'}
          />

          <FilterItem
            iconType
            onClick={() => {
              setBottomTabFilters(2);
              SecureStoragePlugin.set({ key: "bottomBarFilter", value: "2" });
            }}
            description={"Pendentes"}
            selected={bottomTabFilters == 2}
            colorBorderBottom={null}
            enableBorderBottom={false}
            icon={<HourglassEmptyIcon />}
            tooltip={'Clique para mostrar as pedidos extras que estão pendentes.'}
          />

          <FilterItem
            iconType
            onClick={() => {
              setBottomTabFilters(1);
              SecureStoragePlugin.set({ key: "bottomBarFilter", value: "1" });
            }}
            description={"Encerrados"}
            selected={bottomTabFilters == 1}
            colorBorderBottom={null}
            enableBorderBottom={false}
            icon={<QueryBuilderIcon />}
            tooltip={'Clique para mostrar as pedidos extras que já estão encerrados.'}
          />
        </FilterContainer>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (store: any) => ({
  atualizarMsgs: store.clickState.atualizarMsgs
});

export default (connect(mapStateToProps)(PedidoCon));