import { CLICK_UPDATE_VALUE, CREATE_WEBSOCKET, DISCONECTED_WEBSOCKET, 
  USUARIOS_DIGITACAO, CREATE_EVENT_LISTENER, SCROLL } from './actionTypes';

export const atualizarMsgs = (tipoAcao, value, idUsuarioPortalRedux, nomeUsuarioDigitacao, registroSend) => ({
  type: CLICK_UPDATE_VALUE,
  tipoAcao: tipoAcao,
  atualizarMsgs: value,
  idUsuarioPortalRedux: idUsuarioPortalRedux,
  nomeUsuarioDigitacao: nomeUsuarioDigitacao,
  registroSend: registroSend
});

export const createWebSocket = (value) => ({
  type: CREATE_WEBSOCKET,
  created: value
});

export const disconectedWebSocket = (value) => ({
  type: DISCONECTED_WEBSOCKET,
  disconected: value
});

export const createEventListener = (value) => ({
  type: CREATE_EVENT_LISTENER,
  created: value
});

export const usuariosDigitacao = (value) => ({
  type: USUARIOS_DIGITACAO,
  usuarios: value
});

export const scroll = (value) => ({
  type: SCROLL,
  ultimaMsgAparecendo: value
});