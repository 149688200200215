import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './PedidoProdutoCon.css';
import {
  IonGrid, IonRow, IonCol, IonToast, IonModal, IonButton, IonIcon, IonToolbar, IonBadge, IonSpinner, IonContent
} from '@ionic/react';
import { addCircle, checkmarkOutline, attachOutline, checkmarkDone, chatboxEllipses } from 'ionicons/icons';
import { Popover } from '@material-ui/core';
import ConfigProvider from '../../../../assets/utils/configProvider'
import ajax from '../../../../assets/utils/ajax';
import createFilterFieldAllTypes from '../../../../assets/utils/filter/createFilterFieldAllTypes';
import CardProduto from './cardProduto/CardProduto';
import FilterContainer from '../../../../components/filterContainer/filterContainer';
import FilterItem from '../../../../components/filterContainer/filterItem';
import { Plugins } from '@capacitor/core';
import PedidoProdutoCad from './PedidoProdutoCad';
import AnexoCon from '../anexo/AnexoCon';
import Chat from '../chat/Chat';
import { connect, useDispatch, useStore } from 'react-redux';
import { atualizarMsgs, usuariosDigitacao, disconectedWebSocket } from "../../../../assets/utils/redux/actions";
import globalVariables from '../../../../assets/utils/globalVariables';
import PedidoFinalizar from '../PedidoFinalizar';
import LoadingGif from '../../../../components/loadingGif/LoadingGif';

const conectaWebSocket = require("../../../../assets/utils/conectaWebSocket");
const { SecureStoragePlugin } = Plugins;
const moment = require('moment');
const clearly602 = require("../../../../assets/audios/clearly-602.ogg");

let fieldsFilter = [{
  field: "id",
  type: "int",
  comparison: "eq",
  connector: "AND",
}, {
  field: "barras",
  type: "string",
  comparison: "cn",
  connector: "AND",
}, {
  field: "descricao",
  type: "string",
  comparison: "cn",
  connector: "AND",
}, {
  field: "observacao",
  type: "string",
  comparison: "cn",
  connector: "AND",
}, {
  field: "observacaoPortal",
  type: "string",
  comparison: "cn",
  connector: "AND",
}];

let page = 1;
let start = 0;
let limit = 10;
const msgLoadingDeafult = "Carregando produtos...";

interface ProdutoConProps {
  valueSearch: any;
  cadastroPedido: boolean;
  idPedidoExtra: any;
  descricaoPedido: any;
  novasMensagens: any;
  onSetFocusOnRequestEnd: any;
  propsRouter: any;
}

interface RegistroPedidoExtra {
  id: any;
  tipoFinalidade: any;
  idUsuarioAprovacao: any;
  idUsuarioPortal: any;
  statusPedido: any;
  descricao: any;
  mensagensNovas: any;
}

interface RegistroFinalizar {
  id: any,
  tipoFinalidade: any;
  idUsuarioAprovacao: any;
  idUsuarioPortal: any;
  idUsuarioPortalPedido: any;
}

const defaultRegistroPedidoExtra = {
  id: null,
  tipoFinalidade: null,
  idUsuarioAprovacao: null,
  idUsuarioPortal: null,
  statusPedido: null,
  descricao: "",
  mensagensNovas: 0
};


const PedidoProdutoCon: React.FC<ProdutoConProps> = (props: any) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showPopover, setShowPopover] = useState(false);
  const [showModalAnexo, setShowModalAnexo] = useState(false);
  const [showModalChat, setShowModalChat] = useState(false);
  const [pedidoDisabled, setPedidoDisabled] = useState(true);
  const [idUsuarioPortal, setIdUsuarioPortal] = useState<number>();
  const [notShowLoading, setNotShowLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [ordened, setOrdened] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [produtoSelecionado, setProdutoSelecionado] = useState<any>({});
  const [arrData, setArrData] = useState<any>([]);
  const [pedidoExtra, setPedidoExtra] = useState<RegistroPedidoExtra>(defaultRegistroPedidoExtra);
  const [pedidoSelecionado, setPedidoSelecionado] = useState<RegistroFinalizar>();
  const [arrDataSorted, setArrDataSorted] = useState<any>([{ property: "id", direction: "ASC" }]);
  const [topTabFilters, setTopTabFilters] = useState(0);
  const [bottomTabFilters, setBottomTabFilters] = useState(0);
  const [qtnsFiltros, setQtnsFiltros] = useState({
    total: 0,
    pendentes: 0,
    concluidos: 0
  });
  const dispatch = useDispatch();
  const store = useStore();
  const audio = new Audio(clearly602);

  let { idPedidoExtra }: any = useParams();
  idPedidoExtra = idPedidoExtra !== undefined ? idPedidoExtra : props.idPedidoExtra != null ? props.idPedidoExtra : 0;

  SecureStoragePlugin.get({ key: 'idUsuario' })
    .then((idUsuario: any) => {
      setIdUsuarioPortal(parseInt(idUsuario.value));
    })
    .catch((error: any) => {
      setIdUsuarioPortal(-1);
    });


  useEffect(() => {
    if (showModal == true) {
      window.location.hash = "#produtoModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModal])

  useEffect(() => {
    if (showModalChat == true) {
      window.location.hash = "#chatModal"
    } else {
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, [showModalChat])

  useEffect(() => {
    loadProdutos();
  }, [props.valueSearch, topTabFilters, bottomTabFilters, arrDataSorted]);

  // usado pelo redux
  useEffect(() => {
    findPedidoExtra();
  }, [props.atualizarMsgs]);

  // usado pelo redux
  useEffect(() => {
    if (!props.created) {

      SecureStoragePlugin.get({ key: 'idUsuario' })
        .then((idUsuario: any) => {
          SecureStoragePlugin.get({ key: 'token' })
            .then((token: any) => {
              const baseUrl = new ConfigProvider().getConfig("baseURLG3WSCotacao");
              var baseUrlWebsocket = baseUrl.replace("http", "ws");
              var arrUrl = baseUrlWebsocket.split("/");
              baseUrlWebsocket = arrUrl[0] + "//" + arrUrl[2];

              conectaWebSocket.conn(globalVariables, token.value, baseUrlWebsocket + '/compras/websocket/pedidos/extras/chat/' + (idPedidoExtra + "-" + idUsuario.value),
                () => { },
                (event: any) => callbackOnMessage(event, idUsuario),
                onCallbackOnCloseError);
            })
            .catch((error: any) => null);
        })
        .catch((error: any) => null);
    }
  }, [props.created]);

  const callbackOnMessage = (event: any, idUsuario: any) => {
    dispatch(disconectedWebSocket(false));

    try {
      const data = JSON.parse(event.data);
      if (data.mensagem) {
        if (data.mensagem == "digitandoMsg") {
          let usuariosDigitacaoAux = store.getState().usuariosDigitacao.usuarios;
          let arrayCopy = [...usuariosDigitacaoAux];
          if (arrayCopy != null) {
            const obj = { idPedidoExtra: data.idPedidoExtra, usuario: data.nomeUsuario };
            const pos = arrayCopy.findIndex(object => {
              return object.idPedidoExtra === obj.idPedidoExtra && object.usuario === obj.usuario;
            });
            if (pos === -1 && data.idUsuarioPortal != idUsuario.value) {
              arrayCopy.push(obj);
            }
          }
          dispatch(usuariosDigitacao(arrayCopy));
        }
        else if (data.mensagem == "parouDigitacaoMsg") {
          let usuariosDigitacaoAux = store.getState().usuariosDigitacao.usuarios;
          let arrayCopy = [...usuariosDigitacaoAux];
          if (arrayCopy != null) {
            const obj = { idPedidoExtra: data.idPedidoExtra, usuario: data.nomeUsuario };
            const pos = arrayCopy.findIndex(object => {
              return object.idPedidoExtra === obj.idPedidoExtra && object.usuario === obj.usuario;
            });
            if (pos !== -1) {
              arrayCopy = arrayCopy.slice(pos + 1);
            }
          }
          dispatch(usuariosDigitacao(arrayCopy));
        }
        else {
          const countAtual = store.getState().clickState.atualizarMsgs;
          dispatch(atualizarMsgs(data.mensagem, countAtual + 1, data.idUsuarioPortal, data.nomeUsuario, data.registroSend));

          if (data.mensagem == "saveMsg" && data.idUsuarioPortal != idUsuario.value) {
            findPedidoExtra();

            audio.play()
              .then(() => {
              })
              .catch((error: any) => {
                console.log(error);

                setShowAlert({
                  isOpen: true,
                  color: 'warning',
                  header: 'Aviso',
                  message: "Confirme está mensagem para receber notifacações sonoras de novas mensagens do chat!",
                  buttons: ['OK'],
                  onDidDismiss: () => {
                    setShowAlert({ isOpen: false });
                  }
                });
              })
          }
        }
      }
    }
    catch (exception) {
    }
  }

  const onCallbackOnCloseError = (event: any, error: any) => {
    dispatch(disconectedWebSocket(true));
  }

  const findPedidoExtra = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/exists',
      data: {
        registro: {
          id: idPedidoExtra
        }
      },
      beforeMsgSuccessTrue: function (response: any) {
        setPedidoExtra(response.registro);

        const reg = response.registro;
        setPedidoDisabled(reg != null && (reg.situacao > 2 || moment(moment().format("YYYY-MM-DD")).isAfter(moment(reg.dataTerminoDigitacao))));

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setPedidoExtra(defaultRegistroPedidoExtra);

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFailure: function (response: any) {
        setPedidoExtra(defaultRegistroPedidoExtra);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const loadProdutos = () => {
    page = 1;
    start = 0;

    let filterAva: any = [];
    if (props.valueSearch && props.valueSearch !== undefined) {

      let filterFields = new Array();
      createFilterFieldAllTypes(props.valueSearch, fieldsFilter, filterFields, "cn");
      if (filterFields.length > 0) {
        filterAva.push({
          grupo: filterFields,
          connector: "AND"
        });
        page = 1;
        start = 0;
      }
    }

    var filtrosAux: any = [{
      field: "idPedidoExtra",
      value: idPedidoExtra,
      type: "int",
      comparison: "eq",
      connector: "AND"
    }];
    if (topTabFilters != 0) {
      filtrosAux.push({
        field: "statusProduto",
        value: topTabFilters,
        type: "int",
        comparison: "eq",
        connector: "AND"
      });
    }

    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUsuario: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/consulta/load/app',
          data: {
            page: page,
            start: start,
            limit: 100000,
            filterAva: filterAva,
            filterFix: filtrosAux,
            sort: arrDataSorted,
          },
          fnSetShowLoading: props.valueSearch && props.valueSearch !== undefined ? setNotShowLoading : setShowLoading,
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            setArrData(response.registros);
            if (response.registros.length > 0) {
              if (page == 1) {
                setQtnsFiltros({
                  total: response.total ? response.total : 0,
                  pendentes: response.totalPendentes,
                  concluidos: response.totalConcluidos
                });
              }
            }

            if (props.onSetFocusOnRequestEnd) props.onSetFocusOnRequestEnd(true);
            if (response.responseCallback) response.responseCallback();
          }
        });
      })
      .catch((error: any) => {
        return null
      });
  }

  const onRemoveProduto = () => {
    if (produtoSelecionado.id === null) return;

    setShowLoadingMsg("Removendo produto...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/cadastro/delete/app',
      data: {
        registros: [{
          id: produtoSelecionado.id
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Produto removido com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        loadProdutos();

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const onFinalizarPedido = () => {
    setShowLoadingMsg("Finalizando pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/usuarios/empresas/cadastro/finaliza',
      data: {
        registro: {
          idPedidoExtra: idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
        const pedido = { ...pedidoExtra, ...{ statusPedido: 3 } }
        setPedidoExtra(pedido);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  const usuarioCriador = (pedidoExtra && pedidoExtra.idUsuarioPortal === idUsuarioPortal);

  return (
    <>
      {props.cadastroPedido ?
        <div style={{ textAlign: "center" }}>
          <IonSpinner name="crescent" hidden={!showLoading} />
        </div>
        :
        <LoadingGif show={showLoading} msg={showLoadingMsg} />
      }

      {!props.cadastroPedido ? <IonGrid className="ionGridViewport">
        <IonRow>
          <IonCol className="ionColViewport">
            <FilterContainer justyfy={"space-around"}>
              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(0);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "0" });
                }}
                description={"Todos"}
                value={qtnsFiltros.total ? qtnsFiltros.total : 0}
                selected={topTabFilters == 0}
                colorBorderBottom={null}
                enableBorderBottom={true}
                tooltip={"Clique para mostrar todos os seus pedidos extras."}
              />
              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(1);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "1" });
                }}
                description={"Pendentes"}
                value={qtnsFiltros.pendentes ? qtnsFiltros.pendentes : 0}
                selected={topTabFilters == 1}
                colorBorderBottom={"#76DC7B"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras pendentes'}
              />

              <FilterItem
                valueType
                onClick={() => {
                  setTopTabFilters(2);
                  SecureStoragePlugin.set({ key: "topBarFilter", value: "2" });
                }}
                description={"Concluídos"}
                value={qtnsFiltros.concluidos ? qtnsFiltros.concluidos : 0}
                selected={topTabFilters == 2}
                colorBorderBottom={"#FF9248"}
                enableBorderBottom={true}
                tooltip={'Clique para mostrar os seus pedidos extras concluídos'}
              />
            </FilterContainer>
          </IonCol>
        </IonRow>
      </IonGrid>
        : ""}

      <div className="d-table w-100">
        <div className="d-table-cell tar">
          <IonButton
            hidden={(!usuarioCriador || pedidoDisabled)}
            className='btn-novo-pedido'
            onClick={() => {
              setProdutoSelecionado({ id: null });
              setShowModal(true);
            }}
          >
            ADICIONAR PRODUTO
            <IonIcon slot="start" size='large' icon={addCircle} />
          </IonButton>

          {!props.cadastroPedido && (pedidoExtra.idUsuarioPortal != null ? usuarioCriador : true) ? <IonButton
            hidden={!arrData || arrData.length == 0 || pedidoDisabled || pedidoExtra.statusPedido == 4}
            className={pedidoExtra.statusPedido == 3 ? 'btn-finalizado-pedido' : 'btn-finalizar-pedido'}
            onClick={() => {
              if (pedidoExtra.statusPedido != 3) {
                setPedidoSelecionado({
                  id: pedidoExtra.id,
                  tipoFinalidade: pedidoExtra.tipoFinalidade,
                  idUsuarioAprovacao: pedidoExtra.idUsuarioAprovacao,
                  idUsuarioPortal: idUsuarioPortal,
                  idUsuarioPortalPedido: pedidoExtra.idUsuarioPortal
                });
              }
            }}
          >
            {pedidoExtra.statusPedido == 3 ? "FINALIZADO" : "FINALIZAR"}
            <IonIcon slot="start" size='large' icon={pedidoExtra.statusPedido == 3 ? checkmarkDone : checkmarkOutline} />
          </IonButton>
            : ""}
        </div>
      </div>

      {!showLoading ? <CardProduto
        data={arrData}
        idPedidoExtra={idPedidoExtra}
        descricaoPedido={pedidoExtra.descricao}
        usuarioCriador={usuarioCriador}
        cadastroPedido={props.cadastroPedido}
        pedidoDisabled={pedidoDisabled}
        onSetProdutoSelecionado={setProdutoSelecionado}
        onSetShowModal={setShowModal}
        onSetShowPopover={setShowPopover}
      /> : ""}

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModal}
        onDidDismiss={() => {
          setShowModal(false)
          findPedidoExtra();
        }}
      >
        {showModal ? <PedidoProdutoCad
          itemData={produtoSelecionado}
          idPedidoExtra={idPedidoExtra}
          idUsuarioPortal={idUsuarioPortal}
          cadastroPedido={props.cadastroPedido}
          usuarioCriador={usuarioCriador}
          pedidoDisabled={pedidoDisabled}
          propsRouter={props.propsRouter}
          onSetShowModal={(cond: boolean, refresh: boolean) => {
            if (!cond) {
              loadProdutos();
            }
            setShowModal(cond);
          }}
        />
        : ""}
      </IonModal>

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModalAnexo}
        onDidDismiss={() => setShowModalAnexo(false)}
      >
        {showModalAnexo ?
          <AnexoCon
            idPedidoExtra={idPedidoExtra}
            idUsuarioPortal={idUsuarioPortal}
            propsRouter={props.propsRouter}
            onSetShowModalAnexo={(cond: boolean, refresh: boolean) => {
              setShowModalAnexo(cond);
            }}
          /> : ""}
      </IonModal>

      <IonModal
        cssClass="modalProCad"
        backdropDismiss={false}
        swipeToClose={false}
        isOpen={showModalChat}
        onDidDismiss={() => {
          setShowModalChat(false)
          findPedidoExtra();
        }}
      >
        {showModalChat ?
          <Chat
            idPedidoExtra={idPedidoExtra}
            descricaoPedido={pedidoExtra.descricao}
            pedidoDisabled={pedidoDisabled}
            idUsuarioPortal={idUsuarioPortal}
            propsRouter={props.propsRouter}
            onSetShowModalChat={(cond: boolean, refresh: boolean) => {
              setShowModalChat(cond);
            }}
          /> : ""}
      </IonModal>

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />

      <div style={{ marginBottom: "100px" }} />
      {!props.cadastroPedido ? <div style={{ zIndex: 2, position: "fixed", bottom: "0px", width: "100%", textAlign: "center" }} hidden={false}>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size='12'>
                <div className='div-btn-toolbar-bottoms'>
                  <div className='div-btn-toolbar-bottom' onClick={() => {
                    setShowModalAnexo(true);
                  }}>
                    <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={attachOutline} />
                    <p>ANEXOS</p>
                  </div>

                  <div className='div-btn-toolbar-bottom' onClick={() => {
                    setShowModalChat(true);
                  }}>
                    {pedidoExtra.mensagensNovas != null && pedidoExtra.mensagensNovas > 0
                      ? <IonBadge
                        className="badge-btn-notification-produto"
                        color="success">
                        {pedidoExtra.mensagensNovas}
                      </IonBadge>
                      : ""}
                    <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={chatboxEllipses} />
                    <p>CHAT</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </div>
        : ""}

      <div className="container">
        <Popover
          open={showPopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          // mode="ios"
          onClose={e => {
            setShowPopover(false)
          }}
        >
          <div className="myPopover">
            <p>Deseja realmente remover este produto?</p>
            <IonButton
              color="success"
              onClick={() => {
                onRemoveProduto();
                setShowPopover(false);
              }}
              size="small"
            >
              Remover
            </IonButton>
            <IonButton
              color="danger"
              onClick={() => setShowPopover(false)}
              size="small"
            >
              Cancelar
            </IonButton>
          </div>
        </Popover>
      </div>
      
      {pedidoSelecionado ? <PedidoFinalizar
        id={pedidoSelecionado.id}
        tipoFinalidade={pedidoSelecionado.tipoFinalidade}
        idUsuarioAprovacao={pedidoSelecionado.idUsuarioAprovacao}
        idUsuarioPortalLogado={pedidoSelecionado.idUsuarioPortal}
        idUsuarioPortalPedido={pedidoSelecionado.idUsuarioPortalPedido}
        setPedidoSelecionado={setPedidoSelecionado}
        onCallback={onFinalizarPedido} /> : ""}

    </>
  );
};

const mapStateToProps = (store: any) => ({
  atualizarMsgs: store.clickState.atualizarMsgs,
  created: store.createWebSocket.created
});

export default (connect(mapStateToProps)(PedidoProdutoCon));
