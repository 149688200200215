import React from "react";
import {
  IonIcon, IonCard, IonTitle,
  IonGrid, IonRow, IonCol, IonRouterLink
} from '@ionic/react';
import './CardProduto.css';
import { trashSharp } from 'ionicons/icons';
import Tooltip from '@material-ui/core/Tooltip';
import ShowMoreButton from '../../../../../components/showMoreButton/ShowMoreButton';

const getStatusProduto = (statusProduto: number) => {
  if (statusProduto == 1) return "pendentes";
  if (statusProduto == 2) return "concluidos";
}


interface CardProps {
  data: any;
  idPedidoExtra: number;
  descricaoPedido: any;
  usuarioCriador: boolean;
  pedidoDisabled: boolean;
  cadastroPedido: any;
  onSetProdutoSelecionado: any;
  onSetShowModal: any;
  onSetShowPopover: any;
}

const CardProduto: React.FC<CardProps> = (props) => {
  return (
    <div>
      {!props.cadastroPedido ? <IonTitle>{props.idPedidoExtra + " - " + props.descricaoPedido}</IonTitle> : <IonTitle>Produtos</IonTitle>}
      
      <IonGrid className="card-grid">
        <IonRow>
          {props.data.map((row: any, index: number) => {
            return (
              <IonCol key={index} sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="6" sizeXs="12">
                <IonCard className="card-produto">
                  <div className={"card-header-" + getStatusProduto(row.statusProduto)}>
                    <div className="div-descricao-pedido"><span>{row.idProduto}-{row.produto.digito}</span></div>

                    {props.usuarioCriador && !props.pedidoDisabled ? <div className="div-icon-right">
                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Remover Produto
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para remover este produto.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonIcon
                          onClick={() => {
                            props.onSetProdutoSelecionado(row);
                            props.onSetShowPopover(true);
                          }}
                          slot="end"
                          icon={trashSharp}
                          className="card-icon-right"
                        />
                      </Tooltip>
                    </div> : ""}
                  </div>

                  <IonRouterLink
                    className="router-link"
                    onClick={(el: any) => {
                      if (el.target.tagName == "ION-BUTTON" || el.target.tagName == "BUTTON") return;
                      props.onSetProdutoSelecionado(row);
                      props.onSetShowModal(true);
                    }}
                  >
                    <div className="content-card-produto">
                      <div className="div-top-pro">
                        <p><span className="descricao-produto"><ShowMoreButton title={"Descrição do Produto - " + row.barras} content={row.descricao} limit={60} /></span></p>
                        <br />
                        {row.observacao ? <><p className="text-card"><b>Obs. Comprador:</b> <ShowMoreButton title={"Observação - " + row.id} content={row.observacao} limit={20} /></p></> : ""}                        
                        {row.observacaoPortal ? <><p className="text-card"><b>Sua Obs.:</b> <ShowMoreButton title={"Sua Observação - " + row.id} content={row.observacaoPortal} limit={20} /></p></> : ""}
                      </div>

                      <div className="div-bottom-pro">
                        <p className="text-card-data">
                          <span className="labels-card"><b>Barras</b> &nbsp;</span>
                          <span className="result-label-card">{row.barras != null && row.barras != "" ? row.barras : "--"}</span>
                        </p>

                        <p className="text-card-data">
                          <span className="labels-card"><b>Quantidade</b> &nbsp;</span>
                          <span className="result-label-card">{row.qtdTotal}</span>
                        </p>

                        <p className="text-card-data">
                          <span className="labels-card"><b>Preço Compra</b> &nbsp;</span>
                          <span className="result-label-card">{row.precoCompra != null ? row.precoCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        </p>

                        <p className="text-card-data">
                          <span className="labels-card"><b>Preço Venda</b> &nbsp;</span>
                          <span className="result-label-card">{row.precoVenda != null ? row.precoVenda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        </p>
                      </div>
                    </div>
                  </IonRouterLink>
                </IonCard>
              </IonCol>
            )
          })}
        </IonRow>
      </IonGrid>
    </div >
  );
};

export default CardProduto;
