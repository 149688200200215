import React, { useEffect, useState } from 'react';
import {
  IonHeader, IonContent, IonToolbar, IonButtons, IonButton, IonToast,
  IonGrid, IonRow, IonCol, IonIcon
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import { FormControl, Popover } from '@material-ui/core';
import FilterContainer from '../../../../../components/filterContainer/filterContainer';
import FilterItem from '../../../../../components/filterContainer/filterItem';
import ajax from '../../../../../assets/utils/ajax';
import ConfigProvider from '../../../../../assets/utils/configProvider';
import MyInput from '../../../../../components/form/MyInput';
import Hidden from '@material-ui/core/Hidden';
import {
  G3ArrowBackEfeitoIcon,
  G3ArrowBackEfeitoDisableIcon,
  G3ArrowForwardEfeitoIcon,
  G3ArrowForwardEfeitoDisableIcon,
  G3FecharEfeitoIcon
} from '../../../../../assets/utils/g3icons';
import LoadingGif from '../../../../../components/loadingGif/LoadingGif';
const moment = require('moment');

interface ProdutoFilialCadProps {
  itemData: any;
  idPedidoExtraProduto: any;
  descricaoProduto: any;
  filiais: any;
  onSetFilialSelecionada: any;
  onLoadFiliais: any;
  pedidoDisabled: boolean;
  propsRouter: any;
  onSetShowModal: any;
}

interface Registro {
  id: any,
  idEmpresa: any,
  idPedidoExtraProduto: any;
  idFilial: any;
  idFilialEntrega: any;
  quantidade: any;
  dataEntrega: any;
  observacao?: any;
  observacaoPortal?: any;
  readOnly?: boolean;
}

const novoRegistro = {
  id: 0,
  idEmpresa: 0,
  idPedidoExtraProduto: 0,
  idFilial: 0,
  idFilialEntrega: 0,
  quantidade: 0,
  dataEntrega: "",
  observacao: "",
  observacaoPortal: ""
};

let salvou = false;
let salvarSemMsg = false;
let filiais: any = null;
let statusFilial: any = null;

const PedidoProdutoFilialCad: React.FC<ProdutoFilialCadProps> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [registro, setRegistro] = useState<Registro>(novoRegistro);
  const [disableAnterior, setDisableAnterior] = useState<boolean>(false);
  const [disableProximo, setDisableProximo] = useState<boolean>(false);
  const [focusOnRequestEnd, setFocusOnRequestEnd] = useState<boolean>(false);
  const formRef = React.useRef<any>(null);

  useEffect(() => {
    findProdutoFilialPedido(null, null, salvarSemMsg);
  }, [props]);

  const setDisabledPagination = () => {
    let achou = false;
    props.filiais.reverse();
    let reg = props.filiais.find((item: any) => {
      let res = item.idFilial < props.itemData.idFilial;
      if (res && !achou) {
        achou = true;
        return res;
      }
    });
    if (reg == null) {
      setDisableAnterior(true);
    } else {
      setDisableAnterior(false);
    }

    achou = false;
    props.filiais.reverse();
    reg = props.filiais.find((item: any) => {
      let res = item.idFilial > props.itemData.idFilial;
      if (res && !achou) {
        achou = true;
        return res;
      }
    });
    if (reg == null) {
      setDisableProximo(true);
    } else {
      setDisableProximo(false);
    }
  }

  const findProdutoFilialPedido = (anterior?: any, proximo?: any, naoMostrarMsg?: any) => {
    if (anterior != null || proximo != null) {
      filiais = null;
      let reg = {};

      if (anterior != null) {
        let achou = false;
        props.filiais.reverse();
        reg = props.filiais.find((item: any) => {
          let res = item.idFilial < props.itemData.idFilial;
          if (res && !achou) {
            achou = true;
            return item;
          }
        });

        if (reg != null) {
          let regAux = { ...novoRegistro, ...reg };
          regAux.idFilialEntrega = regAux.idFilial;
          setRegistro(regAux)
          props.onSetFilialSelecionada(reg);
        }
        props.filiais.reverse();
      }
      else {
        let achou = false;
        reg = props.filiais.find((item: any) => {
          let res = item.idFilial > props.itemData.idFilial;
          if (res && !achou) {
            achou = true;
            return item;
          }
        });

        if (reg != null) {
          let regAux = { ...novoRegistro, ...reg };
          regAux.idFilialEntrega = regAux.idFilial;
          setRegistro(regAux);
          props.onSetFilialSelecionada(reg);
        }
      }
      statusFilial = 2;
      return;
    }
    else {
      const existsReg = (registro.id != null && registro.id != 0) || props.itemData.id != null;
      if (!existsReg) {
        let reg = {
          id: props.itemData.id || 0,
          idEmpresa: props.itemData.idEmpresa || 0,
          idPedidoExtraProduto: props.idPedidoExtraProduto,
          idFilial: props.itemData.idFilial,
          idFilialEntrega: props.itemData.idFilial,
          quantidade: 0,
          dataEntrega: "",
          observacao: "",
          observacaoPortal: ""
        }

        setRegistro(reg);
        setDisabledPagination();
        setFocusOnRequestEnd(true);
        return;
      }
    }

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/exists/app',
      data: {
        registro: {
          id: registro.id != null && registro.id != 0 ? registro.id : props.itemData.id,
          idPedidoExtraProduto: props.idPedidoExtraProduto || 0
        }
      },
      fnSetShowLoading: naoMostrarMsg || salvarSemMsg ? () => { } : setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        statusFilial = 1;
        setRegistro(response.registro);
        setDisabledPagination();
        if (response.responseCallback) {
          response.responseCallback()
        }
        if (!naoMostrarMsg && !salvarSemMsg) setFocusOnRequestEnd(true);
        salvarSemMsg = false;
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (!naoMostrarMsg && !salvarSemMsg) setFocusOnRequestEnd(true);
        salvarSemMsg = false;
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (!naoMostrarMsg) setFocusOnRequestEnd(true);
        salvarSemMsg = false;
      }
    });
  }

  const saveProdutoFilial = (value: any, callback: any) => {
    let registroAux = { ...registro, ...value };
    registroAux.idPedidoExtraProduto = props.idPedidoExtraProduto;
    registroAux.id = registroAux.id == 0 ? null : registroAux.id;

    if (registroAux.dataEntrega != null && registroAux.dataEntrega != "" && registroAux.dataEntrega != undefined
      && moment(registroAux.dataEntrega, "DD/MM/YYYY", true).isValid()) {
      registroAux.dataEntrega = moment(registroAux.dataEntrega, "DD/MM/YYYY").format("YYYY-MM-DD");
    }

    formRef.current = registroAux;

    salvarSemMsg = true;

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/' + (registroAux.id ? "update/app" : "save/app"),
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      fnGetRegistro: () => {
        return { ...formRef.current, ...value }
      },
      beforeMsgSuccessTrue: function (response: any) {
        statusFilial = 1;
        formRef.current = response.registro;
        setRegistro(response.registro);
        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      afterMsgSuccessTrue: function (response: any) {
        if (registroAux.id == null) {
          setTimeout(() => {
            props.onLoadFiliais(true, true);
          }, 1000);
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const onLimparFilial = () => {
    if (registro.id === null) return;

    salvarSemMsg = true;

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/delete/app',
      data: {
        registros: [{
          id: registro.id,
          idPedidoExtraProduto: props.idPedidoExtraProduto
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        salvou = true;

        setRegistro({ ...registro, ...{ idFilialEntrega: null, quantidade: 0.0, dataEntrega: '', observacao: '', observacaoPortal: '' } });
        props.onLoadFiliais(true, true);

        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "As informações desta filial foram desfeitas com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  return (
    <>
      <IonHeader className='ionGridViewport-border-red'>
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol className="ionColViewport" size={"4"}>
              <IonToolbar>
                <IonButtons slot="start">
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol className="ionColViewport" style={{ height: "inherit" }} size={"4"}>
              <IonToolbar>
                <IonButtons>
                  <div className="container">
                    <FilterContainer>
                      <FilterItem
                        iconType
                        disabled={disableAnterior}
                        onClick={(e: any) => {
                          if (disableAnterior) return;

                          e.preventDefault();
                          findProdutoFilialPedido(1);
                        }}
                        icon={<div style={{}}> {disableAnterior ? <G3ArrowBackEfeitoDisableIcon className="myIcon" /> : <G3ArrowBackEfeitoIcon className="myIcon" />} </div>}
                        padding={0}
                        tooltip={'Clique para visualizar o produto anterior.'}
                        tolltipTitle={'Navegar: Produto anterior'}
                      />

                      <Hidden smUp>
                        <div style={{ width: '20px', height: "100%" }}></div>
                      </Hidden>

                      <FilterItem
                        iconType
                        disabled={disableProximo}
                        onClick={(e: any) => {
                          if (disableProximo) return;

                          e.preventDefault();
                          findProdutoFilialPedido(null, 1);
                        }}
                        icon={<div style={{}}> {disableProximo ? <G3ArrowForwardEfeitoDisableIcon className="myIcon" /> : <G3ArrowForwardEfeitoIcon className="myIcon" />}</div>}
                        padding={0}
                        tooltip={'Clique para visualizar o próximo produto.'}
                        tolltipTitle={'Navegar: Próximo produto'}
                      />
                    </FilterContainer>
                  </div>
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar>
                <IonButtons slot="end">
                  <FilterContainer>
                    <FilterItem
                      iconType
                      onClick={() => {
                        salvou = false;
                        props.onSetShowModal(false);
                        props.onLoadFiliais();
                      }}
                      icon={<><div style={{ marginLeft: '8px', marginRight: '8px' }}><G3FecharEfeitoIcon className="myIcon" /></div></>}
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={'Ação: Fechar tela'}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent>

        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <LoadingGif show={showLoading} msg={'Carregando filial...'} />

        {!showLoading ? <FormControl
          fullWidth
          variant="outlined"
          required
        >
          <form style={{ margin: 15 }}>
            <>
              <MyInput
                typeComponent="select"
                value={registro.idFilial}
                onSave={saveProdutoFilial}
                required={true}
                options={props.filiais.map((item: any) => {
                  return { value: item.idFilial, display: item.filial.descricao };
                })}
                propsInput={{
                  name: "idFilial",
                  label: "Filial",
                  required: true,
                  disabled: true
                }}
              />

              <MyInput
                typeComponent="select"
                value={registro.idFilialEntrega}
                onSave={saveProdutoFilial}
                required={true}
                options={props.filiais.map((item: any) => {
                  return { value: item.idFilial, display: item.filial.descricao };
                })}
                focusOnRequestEnd={focusOnRequestEnd}
                propsInput={{
                  name: "idFilialEntrega",
                  autoFocus: true,
                  label: "Filial de Entrega",
                  required: true,
                  disabled: props.pedidoDisabled
                }}
              />

              <MyInput
                typeComponent="text"
                value={props.descricaoProduto}
                width="100%"
                propsInput={{
                  placeholder: "Produto",
                  disabled: true
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.quantidade}
                type="number"
                decimal="4"
                width="50%"
                onSave={saveProdutoFilial}
                required={true}
                propsInput={{
                  name: "quantidade",
                  placeholder: "Quantidade",
                  required: true,
                  disabled: props.pedidoDisabled
                }}
              />

              <MyInput
                typeComponent="text"
                type="date"
                value={registro.dataEntrega}
                onSave={saveProdutoFilial}
                disableSaveOnChange={true}
                fixedLabel={true}
                // required={true}
                propsInput={{
                  name: "dataEntrega",
                  placeholder: "Data da Entrega",
                  disabled: props.pedidoDisabled
                }}
                width="50%"
              />

              <MyInput
                typeComponent="text"
                value={registro.observacao}
                propsInput={{
                  name: "observacao",
                  placeholder: "Observação do comprador",
                  multiline: true,
                  rows: 3,
                  disabled: true
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.observacaoPortal}
                onSave={saveProdutoFilial}
                propsInput={{
                  name: "observacaoPortal",
                  placeholder: "Sua Observação",
                  multiline: true,
                  rows: 3,
                  disabled: props.pedidoDisabled
                }}
              />
            </>
          </form>
        </FormControl> : ''}

        <div style={{ marginBottom: "100px" }} />
        <div style={{ zIndex: 2, position: "fixed", bottom: "0px", width: "100%", textAlign: "center" }} hidden={statusFilial != 1 || props.pedidoDisabled}>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol size='12'>
                  <div className='div-btn-toolbar-bottoms'>
                    <div className='div-btn-toolbar-bottom' onClick={() => setShowPopover(true)}>
                      <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={trash} />
                      <p>LIMPAR DADOS</p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </div>

        <div className="container">
          <Popover
            open={showPopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            // mode="ios"
            onClose={e => {
              setShowPopover(false)
            }}
          >
            <div className="myPopover">
              <p>Deseja realmente limpar as informações desta filial?</p>
              <IonButton
                color="success"
                onClick={() => {
                  onLimparFilial();
                  setShowPopover(false);
                }}
                size="small"
              >
                Limpar
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowPopover(false)}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>
        </div>
      </IonContent>
    </>
  );
};

export default PedidoProdutoFilialCad;
