import React, { useEffect, useState } from 'react';
import {
  IonHeader, IonContent, IonToolbar, IonButtons, IonToast, IonButton,
  IonGrid, IonRow, IonCol, IonIcon
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import { FormControl, Popover } from '@material-ui/core';
import './PedidoCad.css';
import FilterContainer from '../../../components/filterContainer/filterContainer';
import FilterItem from '../../../components/filterContainer/filterItem';
import ajax from '../../../assets/utils/ajax';
import ConfigProvider from '../../../assets/utils/configProvider';
import MyInput from '../../../components/form/MyInput';
import {
  G3FecharEfeitoIcon
} from '../../../assets/utils/g3icons';
import PedidoProdutoCon from './produto/PedidoProdutoCon';
import LoadingGif from '../../../components/loadingGif/LoadingGif';
const moment = require('moment');


interface PedidoCadProps {
  valueSearch: any;
  idPedidoExtra: any;
  idUsuarioPortal: any;
  propsRouter: any;
  novasMensagens: any;
  onSetShowModal: any;
  alteraSomenteObservacao?: boolean;
}

interface Registro {
  id: any,
  descricao: any;
  idTipoPedido: any;
  tipoAppPedidoExtra: any;
  tipoFinalidade: any;
  dataPedido: any;
  dataInicioDigitacao: any;
  dataTerminoDigitacao: any;
  dataInicioPromocao: any;
  dataTerminoPromocao: any;
  dataHoraGravacao: any;
  idUsuario: any,
  idGrupoComprador: any,
  observacao?: any;
  observacaoPortal?: any;
  situacao: any;
  readOnly?: boolean;
}

const novoRegistro = {
  id: null,
  descricao: "",
  idTipoPedido: 1,
  tipoAppPedidoExtra: 1,
  tipoFinalidade: 1,
  dataPedido: moment().format("DD/MM/YYYY"),
  dataInicioDigitacao: moment().format("DD/MM/YYYY HH:mm:ss"),
  dataTerminoDigitacao: moment().add('month', 1).format("DD/MM/YYYY HH:mm:ss"),
  dataInicioPromocao: null,
  dataTerminoPromocao: null,
  dataHoraGravacao: null,
  idUsuario: "",
  idGrupoComprador: null,
  observacao: "",
  observacaoPortal: "",
  situacao: 2
};

let salvou = false;
const msgLoadingDeafult = "Carregando pedido extra...";

const PedidoCad: React.FC<PedidoCadProps> = (props) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [showPopover, setShowPopover] = useState(false);
  const [pedidoDisabled, setPedidoDisabled] = useState(false);
  const [obsPortalDisabled, setObsPortalDisabled] = useState(false);
  const [registro, setRegistro] = useState<Registro>(novoRegistro);
  const [usuariosCompradores, setUsuariosCompradores] = useState<[object]>();
  const [gruposCompradores, setGruposCompradores] = useState<[object]>();
  const [focusOnRequestEnd, setFocusOnRequestEnd] = useState<boolean>(false);
  const [observacaoObrigatoria, setObservacaoObrigatoria] = useState<boolean>(false);
  const [idUsuarioPortal, setIdUsuarioPortal] = useState<any>();
  const formRef = React.useRef<any>(null);

  useEffect(() => {
    findPedido();
    findUsuariosCompradores();
    findGruposCompradores();
    findObservacaoObrigatoria();
  }, [props.idPedidoExtra]);


  const findPedido = () => {
    const isNovoReg = registro.id == null && props.idPedidoExtra == null;
    if (isNovoReg) {
      setPedidoDisabled(false);
      setFocusOnRequestEnd(true);
      return;
    }

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/exists',
      data: {
        registro: {
          id: registro.id != null ? registro.id : props.idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        const reg = response.registro;
        const isDisabled = !reg || reg.idUsuarioPortal !== props.idUsuarioPortal || reg.situacao > 2 || moment(moment().format("YYYY-MM-DD HH:mm:ss")).isAfter(moment(reg.dataTerminoDigitacao));
        setPedidoDisabled(isDisabled);

        const isObsDisabled = !reg || reg.situacao > 2 || moment(moment().format("YYYY-MM-DD HH:mm:ss")).isAfter(moment(reg.dataTerminoDigitacao));
        setObsPortalDisabled(isObsDisabled);
        
        setRegistro({
          id: reg.id,
          descricao: reg.descricao,
          idTipoPedido: reg.idTipoPedido,
          tipoAppPedidoExtra: 1,
          tipoFinalidade: reg.tipoFinalidade,
          dataPedido: reg.dataPedido,
          dataInicioDigitacao: reg.dataInicioDigitacao,
          dataTerminoDigitacao: reg.dataTerminoDigitacao,
          dataInicioPromocao: reg.dataInicioPromocao,
          dataTerminoPromocao: reg.dataTerminoPromocao,
          dataHoraGravacao: reg.dataHoraGravacao,
          idUsuario: reg.idUsuario,
          idGrupoComprador: reg.idGrupoComprador,
          observacao: reg.observacao,
          observacaoPortal: reg.observacaoPortal,
          situacao: 2
        });

        setIdUsuarioPortal(reg.idUsuarioPortal);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const findUsuariosCompradores = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/usuarios/compradores/consulta/load',
      data: {
        start: 0,
        page: 1,
        limit: 1000,
        sort: [{ property: "idUsuarioComprador", direction: "ASC" }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        let dados = response.registros;
        // dados.unshift({ idUsuarioComprador: "null", comprador: {nome: "Selecione"} });
        setUsuariosCompradores(dados);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const findObservacaoObrigatoria = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/exists/observacaoObrigatoria',
      data: {
        registro: {
          id: registro.id != null ? registro.id : props.idPedidoExtra
        }
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        if (response.registro && response.registro.pedidoExtraGrupo != null) {
          setObservacaoObrigatoria(true);
        } else {
          setObservacaoObrigatoria(false);
        }

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const findGruposCompradores = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/grupos/compradores/consulta/load',
      data: {
        start: 0,
        page: 1,
        limit: 1000,
        sort: [{ property: "descricao", direction: "ASC" }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        let dados = response.registros;
        // dados.unshift({id: "null", descricao: "Selecione"});
        setGruposCompradores(dados);

        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const savePedido = (value: any, callback: any) => {
    let registroAux = { ...registro, ...value };
    registroAux.id = registroAux.id == 0 ? null : registroAux.id;

    if (moment(registroAux.dataPedido, "DD/MM/YYYY", true).isValid()) {
      registroAux.dataPedido = moment(registroAux.dataPedido, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
    if (moment(registroAux.dataInicioDigitacao, "DD/MM/YYYY HH:mm:ss", true).isValid()) {
      registroAux.dataInicioDigitacao = moment(registroAux.dataInicioDigitacao, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    }
    if (moment(registroAux.dataTerminoDigitacao, "DD/MM/YYYY HH:mm:ss", true).isValid()) {
      registroAux.dataTerminoDigitacao = moment(registroAux.dataTerminoDigitacao, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
    }

    formRef.current = registroAux;

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/' + (registroAux.id ? "update/app" : "save/app"),
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      fnGetRegistro: () => {
        return { ...formRef.current, ...value }
      },
      beforeMsgSuccessTrue: function (response: any) {
        formRef.current = response.registro

        setRegistro(response.registro);
        setIdUsuarioPortal(response.registro.idUsuarioPortal);

        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  const onExcluirPedido = () => {
    if (registro.id === null) return;

    setShowLoadingMsg("Removendo pedido extra...");

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/cadastro/delete/app',
      data: {
        registros: [{
          id: registro.id
        }]
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setRegistro(novoRegistro);
        setShowLoadingMsg(msgLoadingDeafult);
        setShowAlert({
          isOpen: true,
          duration: 4000,
          color: 'success',
          header: 'Sucesso',
          message: "Pedido extra removido com sucesso!",
          buttons: ['OK'],
          onDidDismiss: () => {
            setShowAlert({ isOpen: false });
          }
        });

        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFalse: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      },
      beforeMsgSuccessFailure: function (response: any) {
        setShowLoadingMsg(msgLoadingDeafult);
      }
    });
  }

  return (
    <>
      <IonHeader className='ionGridViewport-border-red'>
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol className="ionColViewport" size={"4"}>
              <IonToolbar>
                <IonButtons slot="start">
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol className="ionColViewport" style={{ height: "inherit" }} size={"4"}>
              <IonToolbar>
              </IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport">
              <IonToolbar>
                <IonButtons slot="end">
                  <FilterContainer>
                    <FilterItem
                      iconType
                      onClick={() => {
                        props.onSetShowModal(false)
                        salvou = false;
                      }}
                      icon={<><div style={{ marginLeft: '8px', marginRight: '8px' }}><G3FecharEfeitoIcon className="myIcon" /></div></>}
                      colorText="#404237"
                      padding={0}
                      tolltipTitle={'Ação: Fechar tela'}
                    />
                  </FilterContainer>
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>

      <IonContent>
        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <LoadingGif show={showLoading} msg={showLoadingMsg} />

        <FormControl
          fullWidth
          variant="outlined"
          required
        >
          <form style={{ margin: 15 }}>
            <>
              <MyInput
                typeComponent="text"
                value={registro.id}
                propsInput={{
                  name: "id",
                  placeholder: "ID",
                  disabled: true
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.descricao}
                onSave={savePedido}
                focusOnRequestEnd={focusOnRequestEnd}
                required={true}
                propsInput={{
                  name: "descricao",
                  placeholder: "Descrição do Pedido",
                  autoFocus: !props.alteraSomenteObservacao,
                  required: true,
                  disabled: pedidoDisabled || props.alteraSomenteObservacao
                }}
              />

              <MyInput
                typeComponent="select"
                value={registro.tipoFinalidade}
                onSave={savePedido}
                required={true}
                options={[{
                  value: 1,
                  display: "Compra"
                }, {
                  value: 2,
                  display: "Cotação"
                }]}
                width="100%"
                propsInput={{
                  name: "tipoFinalidade",
                  label: "Finalidade",
                  required: true,
                  disabled: pedidoDisabled || props.alteraSomenteObservacao
                }}
              />

              <MyInput
                typeComponent="select"
                value={registro.idUsuario}
                onSave={savePedido}
                required={false}
                enableSaveNull={true}
                options={usuariosCompradores != null ? usuariosCompradores.map((item: any) => {
                  return { value: item.idUsuarioComprador, display: item.comprador.nome };
                }) : []}
                width="50%"
                propsInput={{
                  name: "idUsuario",
                  label: "Comprador",
                  disabled: usuariosCompradores == null || registro.idGrupoComprador != null || pedidoDisabled || props.alteraSomenteObservacao
                }}
              />
              
              <MyInput
                typeComponent="select"
                value={registro.idGrupoComprador}
                onSave={savePedido}
                required={false}
                enableSaveNull={true}
                options={gruposCompradores != null ? gruposCompradores.map((item: any) => {
                  return { value: item.id, display: item.descricao };
                }) : []}
                width="50%"
                propsInput={{
                  name: "idGrupoComprador",
                  label: "Grupo Compradores",
                  disabled: gruposCompradores == null || (registro.idUsuario != null && registro.idUsuario != "") || pedidoDisabled || props.alteraSomenteObservacao
                }}
              />

              <MyInput
                typeComponent="text"
                value={registro.observacao}
                onSave={savePedido}
                propsInput={{
                  name: "observacao",
                  placeholder: "Observação do comprador",
                  multiline: true,
                  rows: 3,
                  disabled: true
                }}
              />
              
              <MyInput
                typeComponent="text"
                value={registro.observacaoPortal}
                onSave={savePedido}
                required={observacaoObrigatoria && (registro.id != null ? idUsuarioPortal != null : true)}
                propsInput={{
                  name: "observacaoPortal",
                  placeholder: "Sua Observação",
                  multiline: true,
                  autoFocus: props.alteraSomenteObservacao,
                  rows: 3,
                  disabled: obsPortalDisabled,
                  required: observacaoObrigatoria && (registro.id != null ? idUsuarioPortal != null : true)
                }}
              />
            </>
          </form>
        </FormControl>
        
        {registro.id != null && !props.alteraSomenteObservacao ? <PedidoProdutoCon
          valueSearch={null}
          cadastroPedido={true}
          idPedidoExtra={registro.id}
          descricaoPedido={registro.descricao}
          novasMensagens={props.novasMensagens}
          onSetFocusOnRequestEnd={setFocusOnRequestEnd}
          propsRouter={props.propsRouter}
        /> : ""}

        <IonToast
          isOpen={false}
          cssClass='my-custom-class'
          mode='ios'
          {...showAlert}
        />

        <div style={{ marginBottom: "100px" }} />
        <div
          style={{ zIndex: 2, position: "fixed", bottom: "0px", width: "100%", textAlign: "center" }}
          hidden={pedidoDisabled || registro.id == null || props.alteraSomenteObservacao}
        >
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol size='12'>
                  <div className='div-btn-toolbar-bottoms'>
                    <div className='div-btn-toolbar-bottom' onClick={() => setShowPopover(true)}>
                      <IonIcon className='icon-btn-toolbar-bottom' slot="start" icon={trash} />
                      <p>REMOVER PEDIDO</p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </div>

        <div className="container">
          <Popover
            open={showPopover}
            // mode="ios"
            onClose={e => {
              setShowPopover(false)
            }}
          >
            <div className="myPopover">
              <p>Deseja realmente remover este pedido extra?</p>
              <IonButton
                color="success"
                onClick={() => {
                  onExcluirPedido();
                  setShowPopover(false);
                }}
                size="small"
              >
                Remover
              </IonButton>
              <IonButton
                color="danger"
                onClick={() => setShowPopover(false)}
                size="small"
              >
                Cancelar
              </IonButton>
            </div>
          </Popover>
        </div>
      </IonContent>
    </>
  );
};

export default PedidoCad;