import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  IonPage, IonHeader, IonToast, IonButton, IonToolbar, IonContent, IonButtons,
  IonSearchbar, IonGrid, IonRow, IonCol, IonModal
} from '@ionic/react';
import { Divider } from '@material-ui/core';
import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
import Hidden from '@material-ui/core/Hidden';
import './Viewport.css';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import UsuarioCad from './UsuarioCad';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { G3UserBrancoIcon, G3HomeBrancoIcon, G3SairBrancoIcon, G3SearchIcon } from '../../assets/utils/g3icons';
import Fab from '@material-ui/core/Fab';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { connect } from 'react-redux';
import globalVariables from '../../assets/utils/globalVariables';


const { SecureStoragePlugin } = Plugins;
const firstPage = "/viewport";
let lastSearch = "";
let hasFocused = false;

interface ViewportProps {
  propsRouter: RouteComponentProps,
  onSetIsLogged: any;
  Component: any;
  ComponentChild: any;
}

interface Warning {
  show: boolean,
  url: string;
}

const fabHelp = () => {
  if (window.location.pathname != "/documentacao") {
    return (
      <Fab
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '70px',
          zIndex: 90000,
          backgroundColor: '#e53b3e',
          color: '#FFFFFF'
        }}
        aria-label="edit"
        onClick={() => {
          (window as any).open('/documentacao', '_blank').focus();
        }}
      >
        <LiveHelpIcon />
      </Fab>
    )
  }

}

const Viewport: React.FC<ViewportProps> = (props: any) => {
  const { propsRouter, onSetIsLogged, Component, ComponentChild } = props;
  const [valueSearch, setValueSearch] = useState<string>();
  const [valueSearchAux, setValueSearchAux] = useState<string>();
  const [enableSearch, setEnableSearch] = useState<boolean>();
  const [searchOn, setSearchOn] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showWarning, setShowWarning] = useState<Warning>({ show: false, url: '' });
  const [showAlert, setShowAlert] = useState<any>();

  useEffect(() => {
    if (props.disconected) {
      setShowAlert({
        isOpen: true,
        color: 'warning',
        header: 'Aviso',
        message: "Por algum motivo você perdeu a conexão com as notificações dos chats. Atualize a página para tentar reconectar.",
        buttons: ['OK'],
        onDidDismiss: () => {
          setShowAlert({ isOpen: false });
        }
      });
    }
    else {
      setShowAlert({ isOpen: false });
    }
  }, [props.disconected])

  useEffect(() => {
    if (enableSearch) {
      setTimeout(() => {
        setEnableSearch(false);
      }, 1000);

      const value = valueSearchAux;
      const valueAux = !value || value === undefined ? '' : value;
      onSearch(valueAux);
    }
  }, [enableSearch]);

  useEffect(() => {
  }, [valueSearch]);

  const onSearch = (value: any) => {
    if (lastSearch === value) return;
    lastSearch = !value || value === undefined || value.trim() === "" ? '' : value;
    setValueSearch(lastSearch);
  }

  const sair = () => {
    async function clear() {
      await SecureStoragePlugin.remove({ key: "idEmpresa" });
      await SecureStoragePlugin.remove({ key: "login" });
      await SecureStoragePlugin.remove({ key: "token" });
      await SecureStoragePlugin.remove({ key: "nomeUsuario" });

      // fecha a conexão com o websocket
      if (globalVariables.socketTest != null) {
        const ws: any = globalVariables.socketTest;
        ws.close(4999);
        globalVariables.socketTest = null;
      }

      onSetIsLogged(false);
    }
    clear();
  }

  const inputRef = React.createRef<HTMLInputElement>();
  const ionSearchbar = <IonSearchbar
    debounce={1000}
    onIonChange={e => {
      const value = e.detail.value;
      const valueAux = !value || value === undefined ? '' : value;

      onSearch(valueAux);
    }}
    onKeyDown={e => {
      if (e.keyCode === 13) {
        onSearch(e.currentTarget.value);
      }
    }}
    value={valueSearch}
    placeholder="Pesquisar"
    title=""
    enterkeyhint="search"
    type="search"
    animated
  />

  const inputBase = <InputBase
    endAdornment={<IconButton aria-label="menu"
      onClick={() => {
        setValueSearchAux("");
        onSearch("");
      }}
    >
      <HighlightOffIcon />
    </IconButton>}
    startAdornment={<IconButton aria-label="menu"
      onClick={() => {
        setSearchOn(!searchOn)
      }}
    >
      <ArrowBackIcon />
    </IconButton>
    }
    autoFocus={true}
    value={valueSearchAux}
    fullWidth={true}
    placeholder="Pesquisar"
    inputProps={{ ref: inputRef, 'aria-label': 'Pesquisar', style: { width: '100%' } }}
    onChange={e => {
      const value = e.target.value;
      const valueAux = !value || value === undefined ? '' : value;

      setValueSearchAux(valueAux);

      setTimeout(() => {
        if (!enableSearch) {
          setEnableSearch(true);
        }
      }, 1000);
    }}
    onKeyDown={e => {
      if (e.keyCode === 13) {
        onSearch(e.currentTarget.value);
      }
    }}
  />

  if (searchOn && !hasFocused) {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        hasFocused = true;
      }
    }, 500);
  }
  else if (!searchOn) {
    hasFocused = false;
  }

  return (

    <IonPage>
      {fabHelp()}
      <IonHeader>
        <IonGrid className="ionGridViewport">
          <IonRow>
            <IonCol hidden={searchOn} className="ionColViewport" size={"6"}>
              <IonToolbar>
                <IonButtons slot="start">
                  <Tooltip
                    arrow
                    hidden={propsRouter.match.url === firstPage}
                    title={
                      <React.Fragment>
                        <h5 style={{ margin: 2, padding: 0 }}>
                          Botão: Página inicial
                        </h5>
                        <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                          Clique Aqui para voltar para a página inicial!
                        </p>
                      </React.Fragment>
                    }
                  >
                    <IonButton
                      title=""
                      hidden={propsRouter.match.url === firstPage}
                      color="light"
                      onClick={() => {
                        propsRouter.history.push("/viewport");
                      }}
                    >
                      <G3HomeBrancoIcon className="myIcon" />
                    </IonButton>
                  </Tooltip>

                  <Tooltip
                    hidden={propsRouter.match.url !== firstPage}
                    arrow
                    title={
                      <React.Fragment>
                        <h5 style={{ margin: 2, padding: 0 }}>
                          Botão: Perfil do usuário
                        </h5>
                        <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                          Clique aqui para visualizar e editar o seu perfil!
                        </p>
                      </React.Fragment>
                    }
                  >
                    <IonButton
                      title=""
                      hidden={propsRouter.match.url !== firstPage}
                      color="light"
                      onClick={() => {
                        setShowUserModal(!showUserModal)
                      }}
                    >
                      <G3UserBrancoIcon className="myIcon" />
                    </IonButton>
                  </Tooltip>
                </IonButtons>
              </IonToolbar>
            </IonCol>

            <IonCol style={{ height: "inherit" }} className="ionColViewport" size={searchOn ? "12" : "6"}>
              <IonToolbar>
                <Hidden xsDown>
                  <IonButtons slot="end">
                    {ionSearchbar}
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <h5 style={{ margin: 2, padding: 0 }}>
                            Finalizar sua sessão de usuário
                          </h5>
                          <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                            Clique aqui para finalizar a sua sessão.
                          </p>
                          <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                            Após clicar aqui você precisará efetuar o login novamente para poder acessar os pedidos extras
                          </p>
                        </React.Fragment>
                      }
                    >
                      <IonButton
                        title=""
                        hidden={propsRouter.match.url !== firstPage}
                        color="light" onClick={() => sair()}
                      >
                        <G3SairBrancoIcon className="myIcon" />
                      </IonButton>
                    </Tooltip>
                  </IonButtons>
                </Hidden>

                <Hidden smUp>
                  <div hidden={!searchOn} style={{ width: "100%", height: "50px", backgroundColor: "#FFFFFF", paddingTop: "5px" }}>
                    {inputBase}
                  </div>

                  <IonButtons hidden={searchOn} slot="end">
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <h5 style={{ margin: 2, padding: 0 }}>
                            Campo de pesquisa
                          </h5>
                          <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                            Digite aqui o que deseja pesquisar na página.
                          </p>
                        </React.Fragment>
                      }
                    >
                      <IonButton
                        color="light" onClick={() => setSearchOn(true)} title=''
                      >
                        <G3SearchIcon className="myIcon" />
                      </IonButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <h5 style={{ margin: 2, padding: 0 }}>
                            Finalizar sua sessão de usuário
                          </h5>
                          <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                            Clique aqui para finalizar a sua sessão.
                          </p>
                          <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                            Após clicar aqui você precisará efetuar o login novamente para poder acessar os pedidos extras
                          </p>
                        </React.Fragment>
                      }
                    >
                      <IonButton
                        title=""
                        hidden={propsRouter.match.url !== firstPage}
                        color="light" onClick={() => sair()}
                      >
                        <G3SairBrancoIcon className="myIcon" />
                      </IonButton>
                    </Tooltip>
                  </IonButtons>
                </Hidden>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
        <Divider style={{ height: 5, backgroundColor: '#E53B3E' }} />
      </IonHeader>

      <IonContent>
        <Component
          valueSearch={valueSearch}
          setValueSearch={onSearch}
          ComponentChild={ComponentChild}
          propsRouter={propsRouter}
          setShowWarning={setShowWarning}
        />
      </IonContent>

      <IonModal backdropDismiss={false} cssClass="modalProCad" isOpen={showUserModal} onDidDismiss={() => {
        setShowUserModal(false)
      }}>
        <UsuarioCad closeModal={() => {
          setShowUserModal(false)
        }}
        />
      </IonModal>

      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />

    </IonPage>
  );
};

const mapStateToProps = (store: any) => ({
  disconected: store.disconectedWebSocket.disconected
});

export default (connect(mapStateToProps)(Viewport));
