export const conn = (globalVariables:any, token: any, url: any, callbackOnOpen?: any, callbackOnMessage?: any, callbackOnCloseError?: any) => {
    var socket = new WebSocket(url);

    socket.onopen = (event) => {
        var tok = "{\"token\": \"" + token + "\"}";
        socket.send(tok);

        globalVariables.socketTest = socket;
    };

    socket.onmessage = (event) => {
        if (event.data.toString().indexOf("esperando autenticação") === -1) {
            if (callbackOnMessage) callbackOnMessage(event);
        }
        console.log(`[message] Dados recebidos do servidor: ${event.data}`);
    };

    socket.onclose = (event) => {
        if (event.wasClean) {
            console.log(`[close] Conexão fechada, code=${event.code} reason=${event.reason}`);
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log(event);
            console.log('[close] Conexão fechada');
        }
        if (callbackOnCloseError) callbackOnCloseError(event, null);

        // se o user fez logout então não tenta reconectar
        // if (event.code !== 4999) conn(globalVariables, token, url, callbackOnOpen, callbackOnMessage, null);
    };

    socket.onerror = (error: any) => {
        console.log(`[error] ${error.message}`);
        if (callbackOnCloseError) callbackOnCloseError(null, error);

        // conn(globalVariables, token, url, callbackOnOpen, callbackOnMessage, null);
    };
}