import React, { useState, useEffect } from 'react';
import './PedidoCon.css';
import {
  IonButton,
  IonButtons,
  IonContent, IonHeader, IonIcon, IonSpinner, IonTitle, IonToolbar
} from '@ionic/react';
import ConfigProvider from '../../../assets/utils/configProvider'
import ajax from '../../../assets/utils/ajax';
import callReport1 from '../../../assets/utils/callReport1';
import { Plugins } from '@capacitor/core';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const { SecureStoragePlugin } = Plugins;
const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    margin: {
      margin: 5,
      marginTop: 10
    },
  }),
);

const MyInputTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#525252',
      fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#525252',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#525252',
        borderWidth: '2.5px'
      },
    },
  },
})(TextField);

const FormControlSelect = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#525252',
      fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#525252',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#525252',
        borderWidth: '2.5px'
      },
    },
  },
})(FormControl);

interface PedidoExtraReg {
  id: any;
  descricao: any;
}

interface ProdutoPedidoExtraReg {
  id: any;
  barras: any;
  descricao: any;
  produto: {
    idProduto: any;
    digito: any;
  }
}

interface TipoPedidoExtraReg {
  id: any;
  descricao: any;
}

interface CompradorReg {
  idUsuarioComprador: any;
  comprador: {
    nome: any
  }
}

interface PedidoRelProps {
  setAuxShowModalRelatorio: any;
}

interface CallReport1Props {
  url: any;
  format: any;
  nameFile: any;
  records: any;
  sort: any;
  delimiter: any;
  breakLine: any;
  validaFilialLiberada: any;
  filiais: any;
}

let inputRef = React.createRef<HTMLInputElement>();

const PedidoRel: React.FC<PedidoRelProps> = (props: any) => {
  const [formato, setFormato] = React.useState<string>("PDF");
  const [zebrado, setZebrado] = React.useState<number>(1);
  const [pedidosList, setPedidosList] = useState<[PedidoExtraReg]>();
  const [pedidos, setPedidos] = React.useState<number[]>([]);
  const [loadingPedidos, setLoadingPedidos] = React.useState<Boolean>(false);
  const [tiposPedidosList, setTiposPedidosList] = useState<[TipoPedidoExtraReg]>();
  const [tiposPedidos, setTiposPedidos] = React.useState<number[]>([]);
  const [loadingTiposPedidos, setLoadingTiposPedidos] = React.useState<Boolean>(false);
  const [produtosPedidosList, setProdutosPedidosList] = useState<[ProdutoPedidoExtraReg]>();
  const [produtosPedidos, setProdutosPedidos] = React.useState<number[]>([]);
  const [loadingProdutosPedidos, setLoadingProdutosPedidos] = React.useState<Boolean>(false);
  const [finalidade, setFinalidade] = React.useState<number>(0);
  const [dataInicial, setDataInicial] = React.useState<any>();
  const [dataFinal, setDataFinal] = React.useState<any>();
  const [compradoresList, setCompradoresList] = useState<[CompradorReg]>();
  const [compradores, setCompradores] = React.useState<number[]>([]);
  const [loadingCompradores, setLoadingCompradores] = React.useState<Boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    loadPedidos();
  }, []);

  const loadPedidos = () => {
    const dataAtual = moment().format("YYYY-MM-DD");

    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUsuario: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/consulta/load/app',
          data: {
            page: 1,
            start: 0,
            limit: 1000,
            filterAva: [],
            filterFix: [{
              field: "idUsuarioPortal",
              value: idUsuario.value,
              type: "int",
              comparison: "eq",
              connector: "AND"
            }],
            sort: [{ property: "id", direction: "ASC" }],
          },
          noMsg: "",
          fnSetShowLoading: setLoadingPedidos,
          fnSetShowAlert: () => { },
          beforeMsgSuccessTrue: function (response: any) {
            if (inputRef.current && !inputRef.current.disabled) {
              inputRef.current.focus();
            }

            setPedidosList(response.registros);
            loadTiposPedidos();

            if (response.responseCallback) response.responseCallback();
          },
          beforeMsgSuccessFalse: function (response: any) {
            if (inputRef.current && !inputRef.current.disabled) {
              inputRef.current.focus();
            }

            loadTiposPedidos();
          }
        });
      })
      .catch((error: any) => {
        console.log("Erro: " + error);
        return null
      });
  }

  const loadTiposPedidos = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/tipos/consulta/load',
      data: {
        page: 1,
        start: 0,
        limit: 1000,
        filterAva: [],
        filterFix: [],
        sort: [{ property: "id", direction: "ASC" }],
      },
      noMsg: "",
      fnSetShowLoading: setLoadingTiposPedidos,
      fnSetShowAlert: () => { },
      beforeMsgSuccessTrue: function (response: any) {
        setTiposPedidosList(response.registros);

        loadProdutosPedidos();

        if (response.responseCallback) response.responseCallback();
      },
      beforeMsgSuccessFalse: function (response: any) {
        loadProdutosPedidos();
      }
    });
  }

  const loadProdutosPedidos = () => {
    SecureStoragePlugin.get({ key: 'idUsuario' })
      .then((idUsuario: any) => {
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/consulta/load/app',
          data: {
            page: 1,
            start: 0,
            limit: 1000,
            filterAva: [],
            filterFix: [{
              field: "pedidoExtra.idUsuarioPortal",
              value: idUsuario.value,
              type: "int",
              comparison: "eq",
              connector: "AND"
            }],
            sort: [{ property: "id", direction: "ASC" }],
          },
          noMsg: "",
          fnSetShowLoading: setLoadingProdutosPedidos,
          fnSetShowAlert: () => { },
          beforeMsgSuccessTrue: function (response: any) {
            setProdutosPedidosList(response.registros);

            loadCompradores();

            if (response.responseCallback) response.responseCallback();
          },
          beforeMsgSuccessFalse: function (response: any) {
            loadCompradores();
          }
        });
      })
      .catch((error: any) => {
        console.log("Erro: " + error);
        return null
      });
  }

  const loadCompradores = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/usuarios/compradores/consulta/load',
      data: {
        start: 0,
        page: 1,
        limit: 1000,
        sort: [{ property: "idUsuarioComprador", direction: "ASC" }]
      },
      noMsg: "",
      fnSetShowLoading: setLoadingCompradores,
      fnSetShowAlert: () => { },
      beforeMsgSuccessTrue: function (response: any) {
        setCompradoresList(response.registros);
        if (response.responseCallback) response.responseCallback();
      }
    });
  }

  const geraRelatorio = () => {
    let url = new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/relatorio/txt';
    if (formato === "PDF") {
      url = new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/relatorio/pdf';
    }

    const reg = {
      formato: formato,
      zebrado: zebrado.toString(),
      idPedidoExtra: pedidos.toString().replace("[", "").replace("]", ""),
      idTipoPedido: tiposPedidos.toString().replace("[", "").replace("]", ""),
      tipoFinalidade: finalidade === 0 ? null : finalidade,
      idPedidoExtraProduto: produtosPedidos.toString().replace("[", "").replace("]", ""),
      dataInicial: dataInicial || null,
      dataFinal: dataFinal || null,
      idUsuario: compradores.toString().replace("[", "").replace("]", "")
    };

    const pedidosTitulo = reg.idPedidoExtra != null && reg.idPedidoExtra != "" ? (" (" + reg.idPedidoExtra + ")") : "";

    let propsReport = {} as CallReport1Props;
    propsReport.url = url;
    propsReport.format = formato;
    propsReport.nameFile = "Pedidos Extras" + pedidosTitulo + "." + (formato == "PDF" ? "pdf" : "txt");
    propsReport.records = reg;
    propsReport.sort = [];
    propsReport.delimiter = ";";
    propsReport.breakLine = "\r\n";
    propsReport.validaFilialLiberada = false;
    propsReport.filiais = [];

    callReport1(propsReport);

  }

  const optionsFormato = [{
    value: "PDF",
    display: "PDF"
  }, {
    value: "TXT",
    display: "TXT"
  }];

  const optionsZebrado = [{
    value: 1,
    display: "Sim"
  }, {
    value: 2,
    display: "Não"
  }];

  const optionsFinalidade = [{
    value: 0,
    display: "Todas"
  }, {
    value: 1,
    display: "Compra"
  }, {
    value: 2,
    display: "Cotação"
  }];

  return (
    <>
      <IonHeader translucent>
        <IonToolbar className='bakground-white'>
          <IonTitle>Relatório de Pedidos</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => geraRelatorio()}>
              <IonIcon style={{ fontSize: 24, color: "green" }} slot="start" icon={checkmarkCircle} />
              Gerar
            </IonButton>

            <IonButton onClick={() => props.setAuxShowModalRelatorio(false)}>
              <IonIcon style={{ fontSize: 24, color: "red" }} slot="start" icon={closeCircle} />
              Fechar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='div-modal'>
          <form style={{ margin: 15 }}>
            <>
              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(50% - 10px)" }}>
                <InputLabel id="demo-simple-select-standard-label">Formato</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Formato"
                  inputRef={inputRef}
                  value={formato}
                  onChange={(event, option: any) => {
                    setFormato(option.props.value);
                  }}
                >
                  {optionsFormato.map((item: any, index: any) => {
                    return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
                  })}
                </Select>
              </FormControlSelect>

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(50% - 10px)" }}>
                <InputLabel id="demo-simple-select-standard-label">Zebrado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Zebrado"
                  value={zebrado}
                  onChange={(event, option: any) => {
                    setZebrado(option.props.value);
                  }}
                >
                  {optionsZebrado.map((item: any, index: any) => {
                    return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
                  })}
                </Select>
              </FormControlSelect>

              <MyInputTextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                className={classes.margin}
                autoComplete="off"
                style={{ width: "calc(50% - 10px)" }}
                label={"Data Inicial"}
                type={"date"}
                onChange={(event: any) => {
                  setDataInicial(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  type: "date",
                  min: "2000-01-01",
                  max: "9999-12-31"
                }}
              />

              <MyInputTextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                className={classes.margin}
                autoComplete="off"
                style={{ width: "calc(50% - 10px)" }}
                label={"Data Final"}
                type={"date"}
                onChange={(event: any) => {
                  setDataFinal(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  type: "date",
                  min: "2000-01-01",
                  max: "9999-12-31"
                }}
              />

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(100% - 10px)" }}>
                <Autocomplete
                  multiple
                  id="controllable-states-demo"
                  onChange={(event: any, newValue: PedidoExtraReg[]) => {
                    setPedidos(newValue.map((el) => el.id));
                  }}
                  options={pedidosList != null ? pedidosList : []}
                  getOptionLabel={(option:any) => option.id + " - " + option.descricao}
                  renderInput={params => {
                    const inputProps: any = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        inputProps={inputProps}
                        label={loadingPedidos ? <IonSpinner name="dots" /> : "Pedido"}
                        variant="outlined"
                        size="small" />
                    )
                  }}
                />
              </FormControlSelect>

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(100% - 10px)" }}>
                <Autocomplete
                  multiple
                  id="controllable-states-demo"
                  onChange={(event: any, newValue: TipoPedidoExtraReg[]) => {
                    setTiposPedidos(newValue.map((el) => el.id));
                  }}
                  options={tiposPedidosList != null ? tiposPedidosList : []}
                  getOptionLabel={(option:any) => option.id + " - " + option.descricao}
                  renderInput={params => {
                    const inputProps: any = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        inputProps={inputProps}
                        label={loadingTiposPedidos ? <IonSpinner name="dots" /> : "Tipo Pedido"}
                        variant="outlined"
                        size="small" />
                    )
                  }}
                />
              </FormControlSelect>

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(100% - 10px)" }}>
                <InputLabel id="demo-simple-select-standard-label">Finalidade</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Finalidade"
                  value={finalidade}
                  onChange={(event, option: any) => {
                    console.log(option.props);
                    setFinalidade(option.props.value);
                  }}
                >
                  {optionsFinalidade.map((item: any, index: any) => {
                    return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
                  })}
                </Select>
              </FormControlSelect>

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(100% - 10px)" }}>
                <Autocomplete
                  multiple
                  id="controllable-states-demo"
                  onChange={(event: any, newValue: ProdutoPedidoExtraReg[]) => {
                    setProdutosPedidos(newValue.map((el) => el.id));
                  }}
                  options={produtosPedidosList != null ? produtosPedidosList : []}
                  getOptionLabel={(option:any) => option.barras + " - " + ((option.produto.idProduto || 0) + "-" + (option.produto.digito || 0)) + " - " + option.descricao}
                  renderInput={params => {
                    const inputProps: any = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        inputProps={inputProps}
                        label={loadingProdutosPedidos ? <IonSpinner name="dots" /> : "Produto Pedido Extra"}
                        variant="outlined"
                        size="small" />
                    )
                  }}
                />
              </FormControlSelect>

              <FormControlSelect variant="outlined" size="small" className={classes.margin} style={{ width: "calc(100% - 10px)" }}>
                <Autocomplete
                  multiple
                  id="controllable-states-demo"
                  onChange={(event: any, newValue: CompradorReg[]) => {
                    setCompradores(newValue.map((el) => el.idUsuarioComprador));
                  }}
                  onKeyDown={event => {
                    if (event.keyCode === 9) {
                      event.preventDefault()
                      geraRelatorio();
                    }
                  }}
                  options={compradoresList != null ? compradoresList : []}
                  getOptionLabel={(option:any) => {
                    return option.comprador.nome
                  }}
                  renderInput={params => {
                    const inputProps: any = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        inputProps={inputProps}
                        label={loadingCompradores ? <IonSpinner name="dots" /> : "Comprador"}
                        variant="outlined"
                        size="small"
                      />
                    )
                  }}
                />
              </FormControlSelect>
            </>
          </form>
        </div>
      </IonContent >
    </>
  );
};

export default PedidoRel;