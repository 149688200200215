import React from "react";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import './Message.css';
import { IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import Tooltip from '@material-ui/core/Tooltip';
import { InView } from 'react-intersection-observer';
import { scroll } from "../../../../assets/utils/redux/actions";
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRow: {
      marginLeft: "5px",
      display: "flex"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end"
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      padding: "10px",
      minWidth: 150,
      maxWidth: "95%",
      backgroundColor: "#A8DDFD",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #A8DDFD",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #97C6E3",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
      }
    },

    messageBlueWithoutData: {
      position: "relative",
      marginLeft: "20px",
      padding: "10px",
      backgroundColor: "#A8DDFD",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "10px"
    },

    messageOrange: {
      position: "relative",
      marginRight: "20px",
      padding: "10px",
      backgroundColor: "#f8e896",
      minWidth: 150,
      maxWidth: "95%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #f8e896",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #dfd087",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },

    messageOrangeWithoutData: {
      position: "relative",
      marginRight: "20px",
      padding: "10px",
      backgroundColor: "#f8e896",
      minWidth: 150,
      maxWidth: "95%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px"
    },

    messageContent: {
      width: "100%",
      padding: 0,
      margin: 0,
      backgroundColor: "transparent",
      border: "none",
      resize: "none",
      // mozAppearance: "none",
      outline: "0px none transparent"
    },
    messageLidaRight: {
      display: "flex",
      position: "absolute",
      fontSize: ".85em",
      fontWeight: 300,
      marginTop: "5px",
      right: "10px"
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: 300,
      marginTop: "-23px",
      right: "5px"
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    avatarOrange500: {
      fontSize: 12,
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(2),
      height: theme.spacing(2),
      textAlign: "center",
      margin: 1
    },
    avatarOrange500Hidden: {
      fontSize: 12,
      display: "none !important",
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(2),
      height: theme.spacing(2),
      margin: 1
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    displayName: {
      marginLeft: "20px",
      marginBottom: 10
    }
  })
);

const calcHeight = (lines: any) => {
  let newHeight = (lines * 15);
  return newHeight;
}

const calcWidth = (value: any, maxWidth: any, widthExtra?: any) => {
  var canvas = document.createElement("canvas");
  var context = canvas.getContext("2d");
  let maiorLinha = 0;

  if (context) {
    context.font = "400 17px 'Open Sans', sans-serif";
    let linhas = value.split(/\n/g);

    for (let linha of linhas) {
      var metrics = context.measureText(linha);
      var widthLinha = metrics.width;
      if (widthLinha > maiorLinha) {
        maiorLinha = Math.floor(widthLinha > maxWidth ? maxWidth - 200 : widthLinha);
      }
    }
  }
  return maiorLinha + (widthExtra != null ? widthExtra : 0);
}

const calcLines = (value: any, maxWidth: any) => {
  var canvas = document.createElement("canvas");
  var context = canvas.getContext("2d");
  let countLinhas = 0;

  if (context) {
    context.font = "400 17px 'Open Sans', sans-serif";
    let linhas = value.split(/\n/g);

    for (let linha of linhas) {
      var metrics = context.measureText(linha);
      var widthLinha = metrics.width;

      if (widthLinha>maxWidth) {
        const linhas = Math.ceil(widthLinha/maxWidth);
        countLinhas += linhas;
      }
      else {
        countLinhas++;
      }
    }
  }
  
  return countLinhas;
}

const existsOutroUserLeitura = (props: any) => {
  let existsOutroUsuario = false;
  for (let usuarioUltimaLeitura of props.usuariosUltimaLeitura) {
    if (usuarioUltimaLeitura.idUsuarioPortal == null || usuarioUltimaLeitura.idUsuarioPortal != props.idUsuarioPortalLogado) {
      existsOutroUsuario = true;
      break;
    }
  }
  return existsOutroUsuario;
}

const existsUserLeitura = (props: any, left?: any) => {
  let existsUsuario = false;
  for (let usuarioLeitura of props.usuariosLeitura) {
    if (usuarioLeitura.idUsuarioPortal != null && usuarioLeitura.idUsuarioPortal == props.idUsuarioPortalLogado) {
      existsUsuario = true;
      break;
    }
  }
  return existsUsuario;
}

export const MessageLeft = (props: any) => {
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";
  const photoURL = props.photoURL ? props.photoURL : "";
  const displayName = props.displayName;
  const classes = useStyles();
  const dispatch = useDispatch();

  const tamanhoIconeuser = 32;
  const width = calcWidth(message, props.width, tamanhoIconeuser);
  const lines = calcLines(message, props.width);
  const height = calcHeight(lines);
  const lidaPorMim = existsUserLeitura(props);

  return (
    <>
      {timestamp ? <br /> : ""}

      <InView delay={0} as="div" onChange={(inView, entry) => {
        if (entry.intersectionRatio != 0) {
          dispatch(scroll(null));
        }

        if (entry.intersectionRatio > 0 && !lidaPorMim) {
          console.log("entrou para salvar leitura de msg: " + props.id);
          dispatch(scroll(props.id));
        }
      }}>
        <div id={lidaPorMim ? "lida-msg" : "msg-" + props.id} className={classes.messageRow}>
          {timestamp
            ? <Avatar
              alt={displayName}
              className={classes.orange}
              src={photoURL}
            ></Avatar>
            : <div style={{ width: tamanhoIconeuser, height: tamanhoIconeuser }}></div>}

          <div>
            {timestamp ? <div className={classes.displayName}>{displayName}</div> : ""}
            <div style={{ width: width }} className={timestamp ? classes.messageBlue : classes.messageBlueWithoutData}>

              {timestamp ? <div className={classes.messageTimeStampRight}>{timestamp}</div> : ""}
              <textarea style={{ height: height, overflowWrap: "normal" }} className={classes.messageContent} value={message} readOnly></textarea>

              <div className={classes.messageLidaRight}>
                {props.usuariosUltimaLeitura.map((item: any, index: any) => {
                  const naoMostraIconeleitura = item.idUsuarioPortal != null && item.idUsuarioPortal == props.idUsuarioPortalMsg && item.idUsuarioPortal == props.idUsuarioPortalLogado;

                  return (
                    <div key={index + "--"} id={!naoMostraIconeleitura ? "avatar-user-" + (item.idUsuario || item.idUsuarioPortal) : "id"}>
                      <Tooltip
                        key={index}
                        id={"avatar-user-" + (item.idUsuario || item.idUsuarioPortal)}
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              {item.nome}
                            </h5>
                          </React.Fragment>
                        }
                      >
                        <Avatar
                          key={index + "-"}
                          alt={item.nome}
                          className={naoMostraIconeleitura ? classes.avatarOrange500Hidden : classes.avatarOrange500}
                          src={""}
                        >
                        </Avatar>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </InView>

      {existsOutroUserLeitura(props) && props.usuariosUltimaLeitura.length > 0 ? <br /> : ""}

    </>
  );
};

export const MessageRight = (props: any) => {
  const classes = useStyles();
  const message = props.message ? props.message : "";
  const timestamp = props.timestamp ? props.timestamp : "";

  const width = calcWidth(message, props.width);
  const lines = calcLines(message, props.width);
  const height = calcHeight(lines);
  const lidaPorMim = existsUserLeitura(props);

  return (
    <>
      {timestamp ? <br /> : ""}

      <div id={lidaPorMim ? "lida-msg" : "msg-" + props.id} className={classes.messageRowRight}>
        <div id="div-msg-right" style={{ width: width }} className={timestamp ? classes.messageOrange : classes.messageOrangeWithoutData}>

          {timestamp ? <div className={classes.messageTimeStampRight}>{timestamp}</div> : ""}

          <div id="div-icon-msg-close" style={{ marginLeft: -35, marginTop: -10, position: "absolute" }}>
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  <h5 style={{ margin: 2, padding: 0 }}>
                    Remover mensagem
                  </h5>
                </React.Fragment>
              }
            >
              <IonIcon
                slot="start"
                size="large"
                icon={closeCircle}
                className="icon-msg-close"
                onClick={() => props.onRemove(props.id)}
              />
            </Tooltip>
          </div>

          <textarea style={{ height: height, overflowWrap: "normal" }} className={classes.messageContent} value={message} readOnly></textarea>

          <div className={classes.messageLidaRight}>
            {props.usuariosUltimaLeitura.map((item: any, index: any) => {
              const mostraIconeleitura = item.idUsuarioPortal != null && item.idUsuarioPortal == props.idUsuarioPortalMsg && item.idUsuarioPortal == props.idUsuarioPortalLogado;
              return (
                <div key={index + "--"} id={"avatar-user-" + (item.idUsuario || item.idUsuarioPortal)}>
                  <Tooltip
                    key={index}
                    arrow
                    title={
                      <React.Fragment>
                        <h5 style={{ margin: 2, padding: 0 }}>
                          {item.nome}
                        </h5>
                      </React.Fragment>
                    }
                  >
                    <Avatar
                      key={index}
                      alt={item.nome}
                      className={mostraIconeleitura ? classes.avatarOrange500Hidden : classes.avatarOrange500}
                      src={""}
                    />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {existsOutroUserLeitura(props) && props.usuariosUltimaLeitura.length > 0 ? <br /> : ""}
    </>
  );
};