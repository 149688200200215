import React from 'react';

import ReactPlayer from 'react-player'



const VideoTutorial: React.FC = () => {
  return(
    <ReactPlayer width="100%" height="100vh" url={"Tutorial.mp4"} controls/>
  )
};

export default VideoTutorial;