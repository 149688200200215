import { USUARIOS_DIGITACAO } from '../actions/actionTypes';
const initialState = {
  usuarios: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USUARIOS_DIGITACAO:
      return {
        ...state,
        usuarios: action.usuarios
      };
    default:
      return state;
  }
};