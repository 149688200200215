import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

interface FilterContainerProps {
  style?: any;
  justyfy?: any;
  backgroundColor?: any;
}

const FilterContainer: React.FC<FilterContainerProps> = (props: any) => {
  return (
    <div style={props.style}>
      <div style={{ width: "100%", height: "inherit", backgroundColor: props.backgroundColor ? props.backgroundColor : "#404237" }}>
        <Hidden smUp>
          <Grid
            container
            direction="row"
            justifyContent={props.justyfy ? props.justyfy : "center"}
            alignItems="center"
          >
            {props.children}
          </Grid>
        </Hidden>
        <Hidden xsDown >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {props.children}
          </Grid>
        </Hidden>
      </div>
    </div>
  )
}

export default FilterContainer