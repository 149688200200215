import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/themeMaterial.js';
import './App.css';
import { Provider } from 'react-redux';
import store from './assets/utils/redux/store';

const loader = document.querySelector('.loader');
if (loader !== null) loader.classList.remove('loader--hide');

const hideLoader = () => loader !== null ? loader.classList.add('loader--hide') : {};

setTimeout(() => ReactDOM.render(

  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App hideLoader={hideLoader} />
    </Provider>
  </ThemeProvider>
  ,
  document.getElementById('root')
), 0);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
