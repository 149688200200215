var moment = require('moment');
moment.locale('pt-br');

const createFilterFieldAllTypes = (value, arrFiltersFields, arrFilters, comparison) => {
  for (let i in arrFiltersFields) {
    const f = arrFiltersFields[i];

    if (f.disable) { continue }

    const connector = f.hasOwnProperty("connector") ? f.connector : "OR";

    if (f.grupo) {
      let filtrosFieldGroup = new Array();
      createFilterFieldAllTypes(value, f.grupo, filtrosFieldGroup);

      if (filtrosFieldGroup.length > 0) {
        arrFilters.push({
          grupo: filtrosFieldGroup,
          connector: connector
        });
      }
    }
    else {
      let valueAux = value.toString();
      if (f.type === "int") {
        if (isNaN(value)) { continue }

        if (valueAux.split("").length > 9) {
          valueAux = valueAux.substr(0, 9);
        }

        if (f.codigo) {
          valueAux = parseInt(valueAux.substring(0, valueAux.length - 1));
        }
        else if (f.digito) {
          valueAux = valueAux.substr(valueAux.length - 1, valueAux.length);
          if (valueAux == null) {
            valueAux = 0;
          }
        }
      }
      else if (f.type === "string") {
        if (value === null) {
          valueAux = "";
        }

        if (f.digito || f.codigo) {
          const isInteger = valueAux.search(/(\D)/g) === -1;
          if (isInteger) {
            if (f.digito) {
              valueAux = valueAux.substr(valueAux.length - 1, valueAux.length);
              if (valueAux == null) {
                valueAux = "";
              }
            } else if (f.codigo) {
              valueAux = valueAux.substr(0, valueAux.length - 1);
            }
          } else {
            valueAux = "";
          }
        }
        valueAux = valueAux.toString();
      }
      else if (f.type === "date") {
        var res = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(value);
        if (res) {
          if (f.value === "") {
            valueAux = "";
          } else {
            const data = value.replace(/\/|\-|\./gi, "");
            const day = data.substring(0, 2);
            const month = data.substring(2, 4);
            const year = data.substring(4, data.length);

            valueAux = moment(month + "/" + day + "/" + year).format("YYYY-MM-DD")
          }
        }
        else {
          continue;
        }
      }
      else {
        continue;
      }

      // se o filtro deve ser feito atraves de palavras chaves
      f.searchByWordKeys = f.hasOwnProperty("searchByWordKeys") ? f.searchByWordKeys : true;

      if (f.searchByWordKeys && f.type === "string") {
        const arrStr = valueAux.split(" ");
        let achouArrStr = false;
        let arrFiltersByKey = [];

        for (let h in arrStr) {
          if (arrStr[h] === "") { continue }
          achouArrStr = true;
          arrFiltersByKey.push({
            field: f.field,
            value: arrStr[h],
            type: f.type,
            comparison: comparison,
            connector: "AND"
          });
        }

        if (achouArrStr) {
          arrFilters.push({
            grupo: arrFiltersByKey,
            connector: f.digito || f.codigo ? "AND" : "OR"
          });
        }
      } else {
        arrFilters.push({
          field: f.field,
          value: valueAux,
          type: f.type,
          comparison: f.comparison,
          connector: connector
        });
      }
    }
  }
  return;
}

export default createFilterFieldAllTypes;