import React, { useEffect, useState } from 'react';
import {
  IonToast, IonTitle
} from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { FormControl } from '@material-ui/core';
import ajax from '../../../../../assets/utils/ajax';
import ConfigProvider from '../../../../../assets/utils/configProvider';
import MyInput from '../../../../../components/form/MyInput';
import LoadingGif from '../../../../../components/loadingGif/LoadingGif';

const { SecureStoragePlugin } = Plugins;
const moment = require('moment');

interface ProdutoFilialCadProps {
  idPedidoExtraProduto: any;
  loadFiliais: any;
}

interface Registro {
  id: any,
  idEmpresa: any,
  idPedidoExtraProduto: any;
  idFilial: any;
  idFilialEntrega: any;
  quantidade: any;
  dataEntrega: any;
  observacao?: any;
  observacaoPortal?: any;
  readOnly?: boolean;
}

const novoRegistro = {
  id: 0,
  idEmpresa: 0,
  idPedidoExtraProduto: 0,
  idFilial: 0,
  idFilialEntrega: 0,
  quantidade: 0,
  dataEntrega: "",
  observacao: "",
  observacaoPortal: ""
};

let salvou = false;
let inputRef: any = React.createRef<HTMLInputElement>();
let inputRefFil: any = React.createRef<HTMLInputElement>();
const msgLoadingDeafult = "Carregando filial...";

const hasWindow = typeof window !== 'undefined';
const getWindowDimensions = () => {
  const width: any = hasWindow ? window.innerWidth : null;
  const height: any = hasWindow ? window.innerHeight : null;
  return {
    width,
    height,
  };
}

const PedidoProdutoCad: React.FC<ProdutoFilialCadProps> = (props: any) => {
  const [showAlert, setShowAlert] = useState<object>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showLoadingMsg, setShowLoadingMsg] = useState<string>(msgLoadingDeafult);
  const [registro, setRegistro] = useState<Registro>(novoRegistro);
  const [arrFiliais, setArrFiliais] = useState<any>([]);
  const formRef = React.useRef<any>(null);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  useEffect(() => {
    SecureStoragePlugin.get({ key: 'ultimaFilialProduto' })
      .then((ultimaFilialProduto: any) => {
        if (ultimaFilialProduto.value != null) {
          const reg = arrFiliais.find((reg: any) => {
            return reg.idFilial == ultimaFilialProduto.value;
          });

          let registroAux = {
            ...novoRegistro,
            idEmpresa: reg.idEmpresa,
            idFilial: ultimaFilialProduto.value,
            idFilialEntrega: ultimaFilialProduto.value
          };

          formRef.current = registroAux;
          setRegistro(registroAux);
        }
      })
      .catch((error: any) => {
      });
  }, [arrFiliais]);

  useEffect(() => {
    if (registro.idFilial != null && registro.idFilial != 0)
      existsFilial(registro.idFilial, registro.idEmpresa);
  }, [registro.idFilial]);

  useEffect(() => {
    loadFiliais();
  }, []);

  const loadFiliais = () => {
    var filtrosAux: any = [{
      field: "idPedidoExtraProduto",
      value: props.idPedidoExtraProduto,
      type: "int",
      comparison: "eq",
      connector: "AND"
    }];

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/consulta/load/app',
      data: {
        page: 1,
        start: 0,
        limit: 100000,
        filterAva: [],
        filterFix: filtrosAux,
        sort: [{ property: "id", direction: "ASC" }],
      },
      fnSetShowLoading: () => { },
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        setArrFiliais(response.registros);
      }
    });
  }

  const existsFilial = (idFilial: any, idEmpresa: any) => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/exists/by/filial/app',
      data: {
        registro: {
          idEmpresa: idEmpresa,
          idFilial: idFilial,
          idPedidoExtraProduto: props.idPedidoExtraProduto
        }
      },
      fnSetShowLoading: () => { },
      fnSetShowAlert: () => { },
      beforeMsgSuccessTrue: function (response: any) {
        formRef.current = response.registro;
        setShowLoading(true);
        setRegistro(response.registro);
        setShowLoading(false);

        inputRef.current.focus();
      },
      beforeMsgSuccessFalse: function (response: any) {
        let registroAux = {
          ...novoRegistro,
          idEmpresa: idEmpresa,
          idFilial: idFilial,
          idFilialEntrega: idFilial
        };

        formRef.current = registroAux;

        setShowLoading(true);
        setRegistro(registroAux);
        setShowLoading(false);

        inputRef.current.focus();
      }
    });
  }

  const saveProdutoFilial = (value: any, callback: any) => {
    let registroAux = { ...registro, ...value };
    registroAux.idPedidoExtraProduto = props.idPedidoExtraProduto;
    registroAux.id = registroAux.id == 0 ? null : registroAux.id;

    if (registroAux.dataEntrega != null && registroAux.dataEntrega != "" && registroAux.dataEntrega != undefined
      && moment(registroAux.dataEntrega, "DD/MM/YYYY", true).isValid()) {
      registroAux.dataEntrega = moment(registroAux.dataEntrega, "DD/MM/YYYY").format("YYYY-MM-DD");
    }

    formRef.current = registroAux;

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/compras/pedidos/extras/produtos/filiais/cadastro/' + (registroAux.id ? "update/app" : "save/app"),
      data: {
        registro: registroAux
      },
      fnSetShowAlert: setShowAlert,
      fnGetRegistro: () => {
        return { ...formRef.current, ...value }
      },
      beforeMsgSuccessTrue: function (response: any) {
        SecureStoragePlugin.set({ key: "ultimaFilialProduto", value: response.registro.idFilial });
        formRef.current = response.registro;
        setRegistro(response.registro);
        salvou = true;
        if (callback) callback(true);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      afterMsgSuccessTrue: function (response: any) {
        if (props.loadFiliais) props.loadFiliais(true, true);
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      },
      beforeMsgSuccessFailure: function (response: any) {
        if (callback) callback(false);
        if (response.responseCallback) {
          response.responseCallback()
        }
      }
    });
  }

  return (
    <>
      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />

      <LoadingGif show={showLoading} msg={showLoadingMsg} />

      {!showLoading ? <FormControl
        fullWidth
        variant="outlined"
        required
      >

        <IonTitle>Filiais: lançamento resumido</IonTitle>
        <form style={{ margin: 15 }}>
          <>
            <MyInput
              typeComponent="select"
              value={registro.idFilial}
              onSave={false}
              onChange={(value: any, option: any) => {
                let registroAux = {
                  ...novoRegistro,
                  idEmpresa: option.idEmpresa,
                  idFilial: value,
                  idFilialEntrega: value
                };
                setRegistro(registroAux);
              }}
              inputRef={inputRefFil}
              required={true}
              options={arrFiliais.map((item: any) => {
                return { value: item.idFilial, display: (item.idFilial + " - " + item.filial.descricao), idEmpresa: item.idEmpresa };
              })}
              width={windowDimensions.width > 500 ? "50%" : "100%"}
              propsInput={{
                name: "idFilial",
                label: "Filial",
                required: true
              }}
            />

            <MyInput
              typeComponent="select"
              value={registro.idFilialEntrega}
              onSave={registro.quantidade != null && registro.quantidade != "" && registro.quantidade != 0 ? saveProdutoFilial : false}
              required={true}
              options={arrFiliais.map((item: any) => {
                return { value: item.idFilial, display: (item.idFilial + " - " + item.filial.descricao), idEmpresa: item.idEmpresa };
              })}
              width={windowDimensions.width > 500 ? "50%" : "100%"}
              propsInput={{
                name: "idFilialEntrega",
                label: "Filial de Entrega",
                tabIndex: "-1",
                required: true
              }}
            />

            <MyInput
              typeComponent="text"
              value={registro.quantidade}
              type="number"
              decimal="4"
              width="50%"
              inputRef={inputRef}
              onSave={saveProdutoFilial}
              required={true}
              propsInput={{
                name: "quantidade",
                placeholder: "Quantidade",
                required: true
              }}
            />

            <MyInput
              typeComponent="text"
              type="date"
              value={registro.dataEntrega}
              onSave={saveProdutoFilial}
              disableSaveOnChange={true}
              fixedLabel={true}
              propsInput={{
                tabIndex: "-1",
                name: "dataEntrega",
                placeholder: "Data da Entrega"
              }}
              width="50%"
            />

            <MyInput
              typeComponent="text"
              value={registro.observacaoPortal}
              onSave={saveProdutoFilial}
              onBlur={() => {
                inputRefFil.current.select();
                inputRefFil.current.focus();
              }}
              propsInput={{
                name: "observacaoPortal",
                placeholder: "Sua Observação",
                multiline: true,
                rows: 3
              }}
            />
          </>
        </form>
      </FormControl> : ''}
    </>
  );
};

export default PedidoProdutoCad;
