import React from "react";
import {
  IonIcon, IonCard, IonImg,
  IonGrid, IonRow, IonCol, IonRouterLink
} from '@ionic/react';
import './CardAnexo.css';
import { trashSharp, cloudDownload } from 'ionicons/icons';
import Tooltip from '@material-ui/core/Tooltip';
import fileCarregadoImg from '../../../../../assets/img/fileCarregado.png'


interface CardProps {
  files: any;
  idProdutoExtra?: number;
  idPedidoExtra?: number;
  idUsuarioPortal: number;
  pedidoDisabled: boolean;
  onSetImagemSelecionada: any;
  onSetShowModalPreview: any;
  onRemoveAnexo: any;
}

const CardAnexo: React.FC<CardProps> = (props) => {

  const isImage = (extensao: any) => {
    return extensao == 'png' || extensao == 'jpg' || extensao == 'jpeg';
  }

  const isAudio = (extensao: any) => {
    return extensao == 'ogg' || extensao == 'mp3' || extensao == 'oga';
  }

  const isVideo = (extensao: any) => {
    return extensao == 'webm' || extensao == 'avi' || extensao == 'mp4' || extensao == 'm4v' || extensao == 'mov' || extensao == 'mpg' || extensao == 'mpeg' || extensao == 'wmv';
  }

  const isDoc = (extensao: any) => {
    return extensao == 'pdf' || extensao == 'txt' || extensao == 'doc' || extensao == 'xls' || extensao == 'xlsx' || extensao == 'docx' || extensao == 'zip' || extensao == '7z';
  }

  const b64toBlob = (b64Data: any, contentType?: any, sliceSize?: any) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadBlob = (blob: any, name: any) => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(link);
  }

  return (
    <div>
      <IonGrid className="card-grid">
        <IonRow>
          {props.files.map((anexo: any, index: number) => {
            const id = anexo.idPedidoExtra ? anexo.idPedidoExtra : anexo.idProdutoExtra;

            return (
              <IonCol key={index} sizeXl="3" sizeLg="4" sizeMd="6" sizeSm="6" sizeXs="12">
                <IonCard className="card-anexo">
                  <div className={"card-header-ignorados"}>
                    <div className="div-icon-left">
                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Baixar Anexo
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para baixar este anexo.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonIcon
                          onClick={() => {
                            const blob = new Blob([b64toBlob(anexo.arquivo, anexo.extensao, 512)], { type: 'application/' + anexo.extensao });
                            downloadBlob(blob, anexo.nome);
                          }}
                          slot="end"
                          icon={cloudDownload}
                          className="card-icon-right"
                        />
                      </Tooltip>
                    </div>

                    <div className="div-descricao-pedido"><span>{anexo.extensao.toString().toUpperCase()}</span></div>

                    {anexo.idUsuarioPortal == props.idUsuarioPortal ? <div className="div-icon-right">
                      <Tooltip
                        arrow
                        title={
                          <React.Fragment>
                            <h5 style={{ margin: 2, padding: 0 }}>
                              Remover Anexo
                            </h5>
                            <p style={{ margin: 2, padding: 0, lineHeight: "1.2em" }}>
                              Clique aqui para remover este anexo.
                            </p>
                          </React.Fragment>
                        }
                      >
                        <IonIcon
                          onClick={() => {
                            props.onRemoveAnexo(anexo.id, id);
                          }}
                          slot="end"
                          icon={trashSharp}
                          className="card-icon-right"
                        />
                      </Tooltip>
                    </div> : ""}
                  </div>

                  <IonRouterLink
                    className="router-link"
                    onClick={(el: any) => {
                      if (el.target.tagName == "ION-BUTTON" || el.target.tagName == "BUTTON") return;

                      if (isImage(anexo.extensao)) {
                        props.onSetImagemSelecionada("data:image/" + anexo.extensao + ";base64," + anexo.arquivo);
                        props.onSetShowModalPreview(true);
                      }
                      else if (!isVideo(anexo.extensao) && !isAudio(anexo.extensao)) {
                        const blob = new Blob([b64toBlob(anexo.arquivo, anexo.extensao, 512)], { type: 'application/' + anexo.extensao });
                        downloadBlob(blob, anexo.nome);
                      }
                    }}
                  >
                    <div className="content-card-anexo">
                      <div className="div-top">
                        {isImage(anexo.extensao)
                          ?
                          <IonImg className="anexo-img" src={"data:image/" + anexo.extensao + ";base64," + anexo.arquivo} />
                          :
                          isVideo(anexo.extensao)
                            ?
                            <video controls={true}>
                              <source src={"data:video/" + anexo.extensao + ";base64," + anexo.arquivo} type={"video/" + anexo.extensao} />
                            </video>
                            :
                            isAudio(anexo.extensao)
                              ?
                              <audio controls={true} src={"data:audio/" + anexo.extensao + ";base64," + anexo.arquivo} />
                              :
                              <IonImg className="anexo-img" src={fileCarregadoImg} />
                        }
                      </div>

                      <div className="div-bottom">
                        <span>{anexo.nome}</span>
                      </div>
                    </div>
                  </IonRouterLink>
                </IonCard>
              </IonCol>
            )
          })}
        </IonRow>
      </IonGrid>
    </div >
  );
};

export default CardAnexo;
