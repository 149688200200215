import React, { useState, useRef } from 'react';
import {
    IonHeader, IonButton, IonToolbar,
    IonGrid, IonRow, IonCol, IonContent, IonPage, IonToast
} from '@ionic/react';
import {
    withStyles
} from '@material-ui/core/styles';
import { Grid, OutlinedInput } from '@material-ui/core';
import ajax from '../../assets/utils/ajax';
import ConfigProvider from '../../assets/utils/configProvider';

interface EsqueciSenhaModal {
    user?: any;
    onSetShowModal: any;
}

const CssOutlinedInput = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: 'black',
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
            color: 'black'
        },
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E53B3E',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-error .MuiInputBase-input': {
            color: '#E53B3E',
        },
        '&.Mui-error .MuiOutlinedInput-inputAdornedEnd': {
            color: '#E53B3E'
        },
        '&.Mui-error .MuiSvgIcon-root': {
            color: '#E53B3E'
        },
    },
})(OutlinedInput);



const EsqueciSenhaModal: React.FC<EsqueciSenhaModal> = (props) => {
    const [showAlert, setShowAlert] = useState({});
    const [step, setStep] = useState(0);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    var button: any = useRef();

    const validateToken = (token: any, senha: any) => {
        ajax({
            url: new ConfigProvider().getConfig("baseURLG3Auth") + '/auth/portal/token/validate',
            data: {
                idUsuario: 0,
                senha: senha,
                token: token,
                tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
            },
            fnSetShowLoading: setShowLoading,
            fnSetShowAlert: setShowAlert,
            beforeMsgSuccessTrue: function (response: any) {
                setStep(1)
                if (response.responseCallback) {
                    response.responseCallback()
                }
            }
        });
    };

    return (
        <IonPage>
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ionColViewport" sizeMd="4">
                            <IonToolbar className="toolbarWhite">
                                <div className="title">ALTERAR SENHA</div>
                            </IonToolbar>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <div hidden={!(step == 0)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Enviamos para os seus e-mails cadastrados, um link para você alterar a sua senha!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>Para sua segurança e privacidade, enviamos um link especial para os seus e-mails. Clicando no mesmo, você poderá alterar sua senha!</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                ref={ref => button = ref}
                                type="submit"
                                disabled={showLoading}
                                onClick={() => {
                                    props.onSetShowModal(false)
                                }}
                            >
                                {"VOLTAR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                    </Grid>
                </div>

                <div hidden={!(step == 1)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Erro ao verificar usuário</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>O usuário informado {props.user} não existe!</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                disabled={showLoading}
                                onClick={() => {
                                    props.onSetShowModal(false)
                                }}
                            >
                                {"VOLTAR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                    </Grid>
                </div>

                <IonToast
                    isOpen={false}
                    cssClass='my-custom-class'
                    mode='ios'
                    {...showAlert}
                />
            </IonContent>
        </IonPage>
    )
}

export default EsqueciSenhaModal