import React, { useState, useEffect, useRef } from 'react';
import {
    IonHeader, IonButton, IonToolbar,
    IonGrid, IonRow, IonCol, IonContent, IonPage, IonToast, IonSpinner
} from '@ionic/react';
import {
    withStyles
} from '@material-ui/core/styles';
import { Grid, FormControl, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ajax from '../../assets/utils/ajax';
import ConfigProvider from '../../assets/utils/configProvider';
import { useHistory } from "react-router-dom";

interface WelcomeModal {
    user?: any;
    onSetShowModal: any;
    token: any;
    typeWelcome?:any;
    email?:any;
}

const CssOutlinedInput = withStyles({
    root: {
        '& .MuiInputBase-input': {
            color: 'black',
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
            color: 'black'
        },
        '& .MuiSvgIcon-root': {
            color: 'black'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#404237',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E53B3E',
            // height: 70,
            maxWidth: 550,
            borderRadius: 20,
            borderWidth: 3
        },
        '&.Mui-error .MuiInputBase-input': {
            color: '#E53B3E',
        },
        '&.Mui-error .MuiOutlinedInput-inputAdornedEnd': {
            color: '#E53B3E'
        },
        '&.Mui-error .MuiSvgIcon-root': {
            color: '#E53B3E'
        },
    },
})(OutlinedInput);

const WelcomeModal: React.FC<WelcomeModal> = (props) => {
    const history = useHistory();
    const [showAlert, setShowAlert] = useState({});
    const [step, setStep] = useState(0);
    const [senha, setSenha] = useState<string>();
    const [senhaError, setSenhaError] = useState<boolean>();
    const [confirmarSenha, setConfirmarSenha] = useState<string>();
    const [confirmarSenhaError, setConfirmarSenhaError] = useState<boolean>();
    const [showPassword, setShowPassword] = useState<boolean>();
    const [showConfirmaPassword, setShowConfirmaPassword] = useState<boolean>();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [senhaErrorMessage, setSenhaErrorMessage] = useState<string>();
    const [confirmarSenhaErrorMessage, setConfirmarSenhaErrorMessage] = useState<string>();

    let refSenha = useRef<HTMLInputElement>(null)

    const validateToken = (token: any, senha: any) => {
        ajax({
            url: new ConfigProvider().getConfig("baseURLG3Auth") + '/auth/portal/token/validate',
            data: {
                idUsuario: 0,
                senha: senha,
                token: token,
                tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
            },
            fnSetShowLoading: setShowLoading,
            fnSetShowAlert: setShowAlert,
            beforeMsgSuccessTrue: function (response: any) {
                setStep(1)
                
                if(response.responseCallback){
                    response.responseCallback()
                }
            }
        });
    };

    const validaTokenNewPass = () =>{
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3Auth") + '/auth/portal/token/exists',
          data: {
            token: props.token,
            tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
          },
          fnSetShowLoading: setShowLoading,
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            setStep(0)
            if(response.responseCallback){
                response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {
            setStep(2)
          }
        });
    }

    const mandaNovoEmail = () =>{
        ajax({
          url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/portal/usuarios/cadastro/create/senha',
          data: {
            email: props.email
          },
          fnSetShowLoading: setShowLoading,
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (response: any) {
            setStep(3)
            if(response.responseCallback){
                response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (response: any) {
            setStep(4)
          }
        });
    }
 
    useEffect(()=>{
        
        validaTokenNewPass()
        
        setTimeout(()=>{
            refSenha?.current?.focus()
        },1000)
    },[])

    return (
        <IonPage>
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ionColViewport" sizeMd="4">
                            <IonToolbar className="toolbarWhite">
                                <div className="title">{props.typeWelcome == "alterar" ?"ALTERAR SENHA" : "CRIAR SENHA"} </div>
                            </IonToolbar>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <div hidden={!(step == 0)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Olá, seja bem-vindo!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>{props.typeWelcome == "alterar" ? "Altere a sua senha para uma que desejar." : "Crie a senha que será utilizada pelo seu usuário." } </p>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ width: "min-width" }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    required
                                >
                                    <div
                                        style={{
                                            margin: "5px"
                                        }}
                                    >
                                        <CssOutlinedInput
                                            value={senha}
                                            placeholder="Senha"
                                            autoComplete="false"
                                            autoFocus={true}
                                            inputRef={refSenha}
                                            type={showPassword ? 'text' : 'password'}
                                            id="custom-css-outlined-input"
                                            error={senhaError}
                                            required={true}
                                            onChange={(e) => {
                                                setSenha(e.target.value);
                                                if (e.target.value.trim() === "") {
                                                    setSenhaError(true)
                                                } else {
                                                    setSenhaError(false)
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="deixar senha visível"
                                                        tabIndex={"-1"}
                                                        onClick={() => {
                                                            setShowPassword(!showPassword);
                                                        }}
                                                        onMouseDown={(event) => {
                                                            event.preventDefault();
                                                        }}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <div
                                            style={{
                                                color: '#E53B3E',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {senhaErrorMessage}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            margin: "5px"
                                        }}
                                    >
                                        <CssOutlinedInput
                                            value={confirmarSenha}
                                            placeholder="Confirmar senha"
                                            autoComplete="false"
                                            type={showConfirmaPassword ? 'text' : 'password'}
                                            id="custom-css-outlined-input"
                                            error={confirmarSenhaError}
                                            required={true}
                                            onChange={(e) => {
                                                setConfirmarSenha(e.target.value);
                                                if (e.target.value !== senha) {
                                                    setConfirmarSenhaError(true)
                                                } else {
                                                    setConfirmarSenhaError(false)
                                                }
                                            }}
                                            onKeyDown={event => {
                                                if (event.keyCode === 13) {
                                                    event.preventDefault()
                                                    if(!senhaError && !confirmarSenhaError){
                                                        validateToken(props.token, senha);
                                                    }  
                                                }
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="deixar senha visível"
                                                        tabIndex={"-1"}
                                                        onClick={() => {
                                                            setShowConfirmaPassword(!showConfirmaPassword);
                                                        }}
                                                        onMouseDown={(event) => {
                                                            event.preventDefault();
                                                        }}
                                                        edge="end"
                                                    >
                                                        {showConfirmaPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <div
                                            style={{
                                                color: '#E53B3E',
                                                fontWeight: 'bold',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {confirmarSenhaErrorMessage}
                                        </div>
                                    </div>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                disabled={showLoading}
                                onClick={()=>{
                                    if(!senhaError && !confirmarSenhaError){
                                        validateToken(props.token, senha);
                                    }
                                }}
                            >
                                {!showLoading ? props.typeWelcome == "alterar" ? "ALTERAR SENHA" : "CRIAR SENHA" : <IonSpinner name="crescent" />}
                            </IonButton>
                        </Grid>
                    </Grid>
                </div>

                <div hidden={!(step == 1)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Pronto!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>Sua senha foi definida com sucesso, agora basta acessar os pedidos extras.</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                onClick={()=>{
                                    window.location.assign("/login/" + props.email);
                                }}
                            >
                                {"IR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                    </Grid>
                </div>

                <div hidden={!(step == 2)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Esse cadastro de senha não está mais ativo!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>O tempo de validade do cadastro de senha expirou ou a senha já foi confirmada.</p>
                            </div>
                        </Grid>
                        <Grid item hidden={props.typeWelcome == "alterar"}>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                onClick={()=>{
                                    mandaNovoEmail();
                                }}
                            >
                                {"ENVIAR NOVO LINK DE CRIAÇÃO DE SENHA"}
                            </IonButton>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                onClick={()=>{
                                    window.location.assign("/login");
                                }}
                            >
                                {"IR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                        
                    </Grid>
                </div>

                <div hidden={!(step == 3)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>E-mail enviado com sucesso!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>Um novo link de criação de senha foi enviado para o seus e-mails cadastrados no portal!</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                onClick={()=>{
                                    window.location.assign("/login");
                                }}
                            >
                                {"IR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                        
                    </Grid>
                </div>  

                <div hidden={!(step == 4)}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <h3>Erro ao enviar e-mail!</h3>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: "center" }}>
                                <p>Um erro ocorreu enquanto tentavamos enviar um novo e-mail de criação de senha para você!</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <IonButton
                                className="btnEntrar"
                                color="primary"
                                type="submit"
                                onClick={()=>{
                                    window.location.assign("/login");
                                }}
                            >
                                {"IR PARA O LOGIN"}
                            </IonButton>
                        </Grid>
                        
                    </Grid>
                </div>              

                <IonToast
                    isOpen={false}
                    cssClass='my-custom-class'
                    mode='ios'
                    {...showAlert}
                />
            </IonContent>
        </IonPage>
    )
}

export default WelcomeModal