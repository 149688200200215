import { combineReducers } from "redux";
import msgsReducer from "./msgsReducer";
import webSocketReducer from "./webSocketReducer";
import webSocketDisconectedReducer from "./webSocketDisconectedReducer";
import eventListenerReducer from "./eventListenerReducer";
import digitacaoReducer from "./digitacaoReducer";
import scrollReducer from "./scrollReducer";

export default combineReducers({
    clickState: msgsReducer,
    createWebSocket: webSocketReducer,
    disconectedWebSocket: webSocketDisconectedReducer,
    createEventListener: eventListenerReducer,
    usuariosDigitacao: digitacaoReducer,
    scroll: scrollReducer
});
