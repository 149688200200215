import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  OutlinedInput, TextField, FormLabel, RadioGroup, FormControlLabel,
  FormHelperText, Radio, FormControl
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { DatePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event';

import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import isMobile from 'ismobilejs';


var validateDate = require("validate-date");

const moment = require('moment');
const formatPTBR = "DD/MM/YYYY";
const formatBD = "YYYY-MM-DD";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    margin: {
      margin: 5,
      marginTop: 10
    },
  }),
);

const FormControlSelect = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#525252',
      fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#525252',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#525252',
        borderWidth: '2.5px'
      },
    },
  },
})(FormControl);

const MyInputTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#525252',
      fontWeight: 'bold'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#525252',
        borderWidth: '1px'
      },
      '&:hover fieldset': {
        borderColor: '#000000',
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#525252',
        borderWidth: '2.5px'
      },
    },
  },
})(TextField);


interface MyOutlinedInputProps {
  typeComponent: any;
  name?: any;
  type?: any;
  decimal?: any;
  currency?: boolean;
  required?: any;
  value?: any;
  msgHelper?: any;
  onSave?: any;
  onChange?: any;
  disableSaveOnChange?: any;
  onKeyDown?: any;
  placeholder?: any;
  autoComplete?: any;
  autoFocus?: any;
  focusOnRequestEnd?: any;
  startAdornment?: any;
  endAdornment?: any;
  disabled?: any;
  width?: any;
  propsInput?: any;
  options?: any;
  getOptionDisabled?: any;
  maxValue?: number;
  minValue?: number;
  maxLength?: number;
  minLength?: number;
  cellphone?: boolean;
  email?: boolean;
  onEnter?: any;
  setValidatedInput?: any;
  label?: any;
  fixedLabel?: any;
  minDate?: any;
  maxDate?: any;
  hidden?: any;
  onOpenDateDialog?: any;
  inputRef?: any;
  focusOnSave?: any;
  propsStyle?: any;
  doFocus?: any;
  onBlur?: any;
  setDisableModificators?: any;
  ref?: any;
  enableSaveNull?: any;
  loadingData?: any;
}


let inputRef = React.createRef<HTMLInputElement>();

const MyInput: React.FC<MyOutlinedInputProps> = (props) => {

  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [oldValue, setOldValue] = useState<any>("");
  const [value, setValue] = useState<any>(props.typeComponent == "select" ? props.value : "");
  const [lastSave, setLastSave] = useState<any>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [digitou, setDigitou] = useState<boolean>(false);
  const [erro, setErro] = useState<boolean>(false);
  const [msgHelper, setMsgHelper] = useState<any>("");
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const isMounted = useRef(true);

  useEffect(() => {
    if (props.setDisableModificators) {
      props.setDisableModificators(props.propsInput.name, saving)
    }
  }, [saving])

  useEffect(() => {
    if (enableSave) {
      if (props.onSave) onSave();
    }
    else {
      if (!saving) {
        if (props.msgHelper) {
          setMsgHelper(props.msgHelper);
        }
        setDigitou(false);

        if (props.focusOnRequestEnd) {
          setTimeout(function () {
            if (isMounted.current) {
              if (inputRef.current && !inputRef.current.disabled) {
                inputRef.current.focus();
              }
              isMounted.current = false;
            }
          }, 700);
        }

        const val = props.value === null ? '' : props.value;
        if (value == val) return;
        setValue(maskValue(val));
      }
    }
  }, [props.value, props.msgHelper, props.focusOnRequestEnd, enableSave, props.inputRef]);

  useEffect(() => {
    if (props.doFocus && inputRef !== null) {
      setTimeout(() => {
        inputRef.current?.focus()
        inputRef.current?.select()
      }, 20)
    }
  }, [props.doFocus])

  const onSave = () => {
    setEnableSave(false);

    if (props.typeComponent == "select") {
      const val = { [props.propsInput.name]: getValue(value) };
      if (!valida(value)) return;

      setOldValue(val);
      setErro(false);
      if (props.setValidatedInput) props.setValidatedInput(false)
      setMsgHelper(<span style={{ fontSize: 12 }}>Salvando...</span>);
      setSaving(true);

      props.onSave(val, (success: boolean) => {
        if (success) setLastSave(val);
        if (!success) setValue(maskValue(lastSave[props.propsInput.name]));

        setTimeout(function () {
          setSaving(false);
        }, 2000);

        success
          ? setMsgHelper(<span style={{ color: 'green', fontSize: 12 }}>
            {"Salvo! "} <InsertEmoticonIcon style={{ fontSize: 14, color: 'green' }} />
          </span>
          )
          : setMsgHelper(<span style={{ color: 'red', fontSize: 12 }}>
            {"Não salvou! "} <SentimentVeryDissatisfiedIcon style={{ fontSize: 14, color: 'red' }} />
          </span>
          );

        const timer = setTimeout(() => {
          if (!saving) 
            setMsgHelper(props.msgHelper ? props.msgHelper : "");
        }, 1000);
        return () => clearTimeout(timer);

      });
    } else {
      const val = { [props.propsInput.name]: getValue(value) };
      if ((props.typeComponent !== "radioGroup" ? JSON.stringify(lastSave) != JSON.stringify(val) : true) && !saving && props.onSave) {
        if (!valida(value)) return;

        setOldValue(val);
        setErro(false);
        if (props.setValidatedInput) props.setValidatedInput(false)
        setMsgHelper(<span style={{ fontSize: 12 }}>Salvando...</span>);
        setSaving(true);

        props.onSave(val, (success: boolean) => {
          if (success) setLastSave(val);
          if (!success) setValue(maskValue(lastSave[props.propsInput.name]));

          setTimeout(function () {
            setSaving(false);
          }, 2000);

          success
            ? setMsgHelper(<span style={{ color: 'green', fontSize: 12 }}>
              {"Salvo! "} <InsertEmoticonIcon style={{ fontSize: 14, color: 'green' }} />
            </span>
            )
            : setMsgHelper(<span style={{ color: 'red', fontSize: 12 }}>
              {"Não salvou! "} <SentimentVeryDissatisfiedIcon style={{ fontSize: 14, color: 'red' }} />
            </span>
            );

          const timer = setTimeout(() => {
            if (!saving) 
              setMsgHelper(props.msgHelper ? props.msgHelper : "");
          }, 1000);
          return () => clearTimeout(timer);

        });
      }
    }


  }

  const validaDate = (dateStr: any) => {
    if (dateStr.length == 8) {
      const date = dateStr.slice(6, 8);
      const month = dateStr.slice(4, 6);
      const year = dateStr.slice(0, 4);
      return validateDate(year + "-" + month + "-" + date, "boolean")
    } else {
      return false
    }
  }

  const valida = (valueOnChange?: any) => {
    const valueAux = valueOnChange !== undefined ? getValue(valueOnChange) : "";
    if (props.required) {
      if (valueAux === "" || valueAux === undefined || valueAux === null) {
        setErro(true);
        if (props.setValidatedInput) props.setValidatedInput(true)
        setMsgHelper("Este campo é obrigatório");
        return false;
      }
      if (props.type === "number" && isNaN(valueAux)) {
        setErro(true);
        if (props.setValidatedInput) props.setValidatedInput(true)
        setMsgHelper("Este campo aceita somente valores numéricos");
        return false;
      }
    }

    if (props.type === "number") {
      if (isNaN(valueAux)) {
        setErro(true);
        if (props.setValidatedInput) props.setValidatedInput(true)
        setMsgHelper("Este campo aceita somente valores numéricos");
        return false;
      }
      if (props.minValue !== undefined && props.minValue !== null) {
        if (parseFloat(valueAux) < props.minValue) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Este campo aceita no mínimo o valor " + props.minValue.toLocaleString("pt-BR"));
          return false;
        }
      }
      if (props.maxValue !== undefined && props.maxValue !== null) {
        if (parseFloat(valueAux) > props.maxValue) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Este campo aceita no máximo o valor " + props.maxValue.toLocaleString("pt-BR"));
          return false;
        }
      }
    }
    else if (props.type === "date") {
      if (valueAux.toString().match(/\d/g) != null) {
        var dateNumbers = valueAux.toString().match(/\d/g).join("")
        if (dateNumbers.length != 8) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Por favor digite uma data valida no formato XXXXXXXX!");
          return false;
        } else {
          if (!validaDate(dateNumbers)) {
            setErro(true);
            if (props.setValidatedInput) props.setValidatedInput(true)
            setMsgHelper("Por favor digite uma data valida no formato XXXXXXXX!");
            return false;
          }
        }
      }
    } else if (props.type === "text") {
      if (props.minLength !== undefined && props.minLength !== null) {
        if (valueAux.toString().length < props.minLength) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Este campo aceita no mínimo " + props.minLength + " caracteres");
          return false;
        }
      }
      if (props.maxLength !== undefined && props.maxLength !== null) {
        if (valueAux.toString().length > props.maxLength) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Este campo aceita no máximo " + props.maxLength + " caracteres");
          return false;
        }
      }
    }

    if (props.email) {
      if (!valueAux.toString().includes("@") || !valueAux.toString().includes(".")) {
        setErro(true);
        if (props.setValidatedInput) props.setValidatedInput(true)
        setMsgHelper("Esse campo necessita ter um formato valido de e-mail!");
        return false;
      }
    }

    if (props.cellphone) {
      if (valueAux.toString().match(/\d/g) != null) {
        if (valueAux.toString().match(/\d/g).join("").length <= 9) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper(" XX XXXXXXXX - Necessário informar DDD + número de telefone! ");
          return false;
        }
        if (valueAux.toString().match(/\d/g).join("").length > 11) {
          setErro(true);
          if (props.setValidatedInput) props.setValidatedInput(true)
          setMsgHelper("Numero de telefone invalido");
          return false;
        }
      }
    }

    setErro(false);
    if (props.setValidatedInput) props.setValidatedInput(false)
    setMsgHelper(props.msgHelper ? props.msgHelper : "");

    return true;
  }

  const getValue = (value: any) => {
    let valueAux = value !== undefined && value !== null ? value : "";
    if (props.type === "number" && valueAux !== "") {
      if (props.decimal) {
        valueAux = valueAux.toString().indexOf(",") !== -1 ? valueAux.toString().replace(/\./g, "") : valueAux.toString();
        const val = valueAux.toString().replace(/\,/g, ".").trim();
        return val === "" ? val : parseFloat(val);
      }
      else {
        const val = valueAux.toString().replace(/[^\d]+$/, "").trim();
        return val === "" ? val : parseInt(val);
      }
    }
    else if (props.type === "date" && valueAux !== "") {
      valueAux = valueAux.toString().replace(/[^\d]/g, "");
      var d = valueAux.toString().match(/^(\d{1,2})(\d{1,2})(\d{4})$/);
      let date = d ? moment((d[3] ? d[3] : moment().format("YYYY")) + "-" + (d[2] ? d[2] : moment().format("MM")) + "-" + (d[1] ? d[1] : moment().format("DD"))) : moment();
      return date.isValid() && valueAux.toString().length >= 8 ? date.format(formatBD) : valueAux;
    }
    else {
      return valueAux;
    }
  }

  const maskDecimal = (value: any, decimal: any) => {
    return getValue(value).toLocaleString("pt-BR", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  const maskNumber = (value: any) => {
    return getValue(value);
  }

  const maskDate = (value: any) => {
    if (!moment(getValue(value)).isValid()) return "";
    return getValue(value) !== "" ? moment(getValue(value)).format(formatPTBR) : getValue(value);
  }

  const maskCellphone = (value: any) => {
    if (getValue(value).toString().match(/\d/g) != null) {
      var valorFinal = "";
      var valor = getValue(value).toString().match(/\d/g).join("")

      if (valor.length >= 10) {
        var valorDDD = valor.slice(0, 2)
        var valorNumero = valor.slice(2, valor.length)
        valorFinal = "(" + valorDDD + ") " + valorNumero.slice(0, valorNumero.length - 4) + "-" + valorNumero.slice(valorNumero.length - 4, valorNumero.length)
      } else {
        valorFinal = valor
      }

      return valorFinal
    } else {
      return getValue(value)
    }

  }

  const maskValue = (value: any) => {
    if (props.type === "number") {
      if (props.decimal) {
        return maskDecimal(value, props.decimal);
      }
      else {
        return maskNumber(value);
      }
    }
    else if (props.type === "date") {
      return maskDate(value);
    }
    else {
      if (props.cellphone) {
        return maskCellphone(value)
      } else {
        return value
      }
    }
  }

  const onChangeOpt = (e: any, option: any) => {
    let valueAux2 = e;
    if (e.target) {
      e.persist();
      valueAux2 = e.target.value;
    }

    setDigitou(true);
    if (props.typeComponent == "select") {
      setValue(valueAux2)

      setTimeout(() => {
        setEnableSave(true);
      }, 2000);

      if (props.onChange) props.onChange(valueAux2, option);

    } else {
      if (props.type === "number") {
        if (props.decimal) {
          let valueState = valueAux2.toString().replace(".", ",");
          valueState = valueState.replace(/[^\d,]/g, "").trim();
          if (valueState.indexOf(",") !== -1) {
            const ini = valueState.substring(0, valueState.indexOf(","));
            const fim = valueState.substring(valueState.indexOf(","), valueState.indexOf(",") + 1 + parseInt(props.decimal));
            valueState = (ini + "" + fim).toString().replace(/[^\d,]/g, "").trim();
            setValue(ini + "" + fim);
          }
          else {
            setValue(valueState);
          }
        }
        else {
          var valor = valueAux2.toString().replace(".", ",")
          setValue(maskValue(valor).toString().trim());
        }
      }
      else if (props.type === "date") {
        let valueState = valueAux2.toString().replace(/[^\d]/g, "").trim();
        valueState = valueState.substring(0, 8);
        setValue(valueState);
      }
      else {
        if (props.cellphone) {
          let valueState = valueAux2.toString().replace("(", "").replace(")", "").replace("-", "").trim();
          setValue(valueState)
        } else {
          setValue(maskValue(valueAux2));
        }
      }

      if (props.type != "date") {
        if (!valida(valueAux2) || props.disableSaveOnChange) return;
      } else {
        if (!valida(valueAux2)) return;
      }

      if (props.type === "date") {
        setEnableSave(true);
      } else {
        setTimeout(() => {
          setEnableSave(true);
        }, 2000);
      }

      if (props.onChange) props.onChange(valueAux2);
    }
  }

  const onBlurOpt = (e: any) => {

    let valueAux2 = e;
    if (e.target) {
      e.persist();
      valueAux2 = e.currentTarget.value;
    }

    if (props.cellphone) {
      let valueState = valueAux2.toString()
      if (valueState.match(/\d/g) != null) {
        valueState = valueState.match(/\d/g).join("")
        setValue(valueState)
      }
    }

    if (!valida(valueAux2)) return;
    setValue(maskValue(valueAux2));
    const val = { [props.propsInput.name]: getValue(valueAux2) };
    if (JSON.stringify(val) != JSON.stringify(oldValue) && getValue(valueAux2) != oldValue
      && (props.typeComponent !== "radioGroup" ? digitou : true)) {
      // setDigitou(false);
      setOldValue(val);
      setEnableSave(true);
    }
    if (props.onKeyDown) props.onKeyDown(valueAux2);
    if (props.onBlur) props.onBlur(value);
  }

  const onFocusOpt = (input: any) => {
    if (props.type == "date") {
      if (input.value.toString().match(/\d/g) != null) {
        setValue(input.value.toString().match(/\d/g).join(""))
      }
    }

    if (props.type == "number") {
      if (input.value.toString().match(/\d/g) != null) {
        setValue(input.value.toString().split('.').join(''))
      }
    }

    if (props.cellphone) {
      if (getValue(input.value).toString().match(/\d/g) != null) {
        setValue(getValue(input.value).toString().match(/\d/g).join(""))
      }
    }

    setDigitou(false);
    setLastSave({ [props.propsInput.name]: getValue(input.value) });
    setOldValue({ [props.propsInput.name]: getValue(input.value) });
    if ((input && input.select) && !isMobile(window.navigator).any) input.select();
  }

  const getDate = () => {
    if (value) {
      var val = value.toString()
      var dates = val.split("/")
      var dataFim: string = dates[2] + "/" + dates[1] + "/" + dates[0]
      return dataFim
    } else {
      return ""
    }
  }

  let displayValue = "";
  if (props.options) {
    for (let option of props.options) {
      if (option.value == (value ? value : props.value)) {
        displayValue = option.display;
        break;
      }
    }
  }

  const tabIndex = props.propsInput.hasOwnProperty("tabIndex") ? { tabIndex: props.propsInput.tabIndex } : {};

  return (
    <Fragment>
      <DatePicker
        hidden={true}
        open={showDatePicker}
        onClose={() => {
          setShowDatePicker(false)
        }}
        onChange={(value: any) => {
          value = moment(value).format(formatPTBR);
          onChangeOpt(value, null)
          onBlurOpt(value);
          // setMsgHelper(<span style={{ fontSize: 12 }}>Salvando...</span>);
          // var date = new Date(value)
          // var dateList = date.toISOString().split("T")[0].split("-")
          // setValue(dateList[2] + "/" + dateList[1] + "/" + dateList[0])
          // console.log(dateList[2] + "/" + dateList[1] + "/" + dateList[0])
          setShowDatePicker(false)

          // setEnableSave(true)
        }}
        value={getDate()}
        minDate={props.minDate ? props.minDate : "0001-01-01"}
        maxDate={props.maxDate ? props.maxDate : "9999-12-31"}
        cancelLabel="Cancelar"
      />

      {props.typeComponent === "select" ?
        <FormControlSelect hidden={props.hidden} variant="outlined" size="small" className={classes.margin} style={{ width: "calc(" + (props.width ? props.width : "100%") + " - 10px)" }}>
          <InputLabel id="demo-simple-select-outlined-label">{props.propsInput.placeholder ? props.propsInput.placeholder : ''}</InputLabel>

          <Autocomplete
            id="combo-box-demo"
            disableClearable={props.propsInput.disableClearable}
            options={props.options}
            getOptionDisabled={(option) => {
              return option.value === props.getOptionDisabled
            }}
            getOptionLabel={(option: any) => option.display}
            onChange={(event: any, option: any) => {
              if (!option && (!props.enableSaveNull || !props.value)) return;
              if (!option && props.enableSaveNull) option = { value: 'null' };
              onChangeOpt(option.value, option);
            }}
            onBlur={(event: any) => {
              if (props.onBlur) props.onBlur(event);
            }}
            value={{ value: (value ? value : props.value), display: displayValue }}
            size={"small"}
            selectOnFocus
            disabled={props.propsInput.disabled}
            renderInput={(params) => {
              return <TextField
                {...params}
                key={value ? value : props.value}
                label={props.propsInput.label ? props.propsInput.label : ''}
                inputRef={props.propsInput.autoFocus || props.doFocus ? inputRef : props.inputRef ? props.inputRef : null}
                disabled={props.propsInput.disabled}
                variant="outlined"
                inputProps={{ ...params.inputProps, ...tabIndex }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {props.loadingData ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            }}
          />

          {/* <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              key={value}
              value={value}
              disabled={props.propsInput.disabled}
              displayEmpty
              onChange={(e: any) => onChangeOpt(e)}
              label={props.propsInput.placeholder ? props.propsInput.placeholder : ''}
              inputRef={props.propsInput.autoFocus || props.doFocus ? inputRef : null}
            >
              {props.options ? props.options.map((item: any, index: any) => {
                return <MenuItem key={index} value={item.value}>{item.value + " - " + item.display}</MenuItem>
              }) : ''}
            </Select> */}

          <FormHelperText>{msgHelper}</FormHelperText>
        </FormControlSelect>
        : props.typeComponent === "text" ?
          <MyInputTextField
            hidden={props.hidden}
            id="outlined-basic"
            variant="outlined"
            size="small"
            className={classes.margin}
            fullWidth
            {...props.propsInput}
            autoComplete="off"
            style={{ width: "calc(" + (props.width ? props.width : "100%") + " - 10px)" }}
            value={value} //maskValue(props.value !== undefined ? props.value : ''
            label={props.propsInput.placeholder ? props.propsInput.placeholder : ''}
            type={props.type == "password" ? showPassword ? "text" : "password" : "text"}
            onChange={(e: any) => onChangeOpt(e, null)}
            onBlur={(e: any) => onBlurOpt(e)}
            onFocus={(e: any) => onFocusOpt(e.currentTarget)}
            error={erro}
            inputRef={props.propsInput.autoFocus || props.doFocus ? inputRef : props.inputRef ? props.inputRef : null}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                event.preventDefault()
                if (props.onEnter) props.onEnter(event)
              }
            }}
            InputLabelProps={{
              shrink: props.fixedLabel
            }}
            inputProps={{ ...tabIndex, min: 0, style: props.propsStyle }}
            InputProps={props.type == "password" ? {
              endAdornment: <InputAdornment position="end">
                <IconButton
                  style={{ marginRight: "-10px" }}
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
              style: props.propsStyle ? props.propsStyle : {}
            } : props.type == "date" ? {
              type: "tel",
              endAdornment: <InputAdornment position="end">
                <IconButton
                  style={{ marginRight: "-10px" }}
                  size="small"
                  tabIndex="-1"
                  aria-label="toggle password visibility"
                  disabled={props.propsInput ? props.propsInput.disabled : false}
                  onClick={() => {
                    if (props.onOpenDateDialog) {
                      props.onOpenDateDialog(() => {
                        setShowDatePicker(!showDatePicker)
                      })
                    } else {
                      setShowDatePicker(!showDatePicker)
                    }
                  }}
                >
                  {<EventIcon />}
                </IconButton>
              </InputAdornment>,
              style: props.propsStyle ? props.propsStyle : {}
            } : props.type == "number" && props.currency ? {
              type: "tel",
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            } : props.type == "number" || props.cellphone ? {
              type: "tel"
            } : props.cellphone ? {
              type: "tel"
            } : {}}
            helperText={<Fragment>
              {msgHelper}
            </Fragment>}
          />
          :
          props.typeComponent === "radioGroup" ?
            <FormControl hidden={props.hidden} component="fieldset" error={erro} style={{ width: "calc(" + (props.width ? props.width : "100%") + " - 10px)" }}>
              <FormLabel component="legend">{props.propsInput.placeholder}{props.propsInput.required ? " *" : ""}</FormLabel>
              <RadioGroup
                {...props.propsInput}
                name={props.name}
                value={value}
                onChange={(e: any) => onBlurOpt(e)}
              >
                {props.options ? props.options.map((item: any, index: any) => {
                  return <FormControlLabel
                    key={index}
                    control={<Radio />}
                    {...item}
                    checked={item.value === value}
                  />
                }) : ''}
              </RadioGroup>
              <FormHelperText>{msgHelper}</FormHelperText>
            </FormControl>
            :
            <OutlinedInput
              hidden={props.hidden}
              id="custom-css-outlined-input"
              className={classes.margin}
              fullWidth
              {...props.propsInput}
              autoComplete="off"
              style={{ width: "calc(" + (props.width ? props.width : "100%") + " - 10px)" }}
              value={value}
              type="text"
              onChange={(e: any) => onChangeOpt(e, null)}
              onBlur={(e: any) => onBlurOpt(e)}
              onFocus={(e: any) => onFocusOpt(e.currentTarget)}
              inputRef={props.propsInput.autoFocus ? inputRef : null}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault()
                  if (props.onEnter) props.onEnter(event)
                }
              }}
              error={erro}
            />}
    </Fragment>
  );
};

export default MyInput;

MyInput.defaultProps = {
  type: "text"
};
