import React, { useEffect, useRef, useState } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import ajax from '../../assets/utils/ajax';
import ConfigProvider from '../../assets/utils/configProvider';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

interface Props {
	aprovar: Boolean,
	idRegistro: String,
	idToken: String
}

const AprovacaoPedidoExtra: React.FC<Props> = (props) => {
	const [showDialog, setShowDialog] = useState<boolean>(true);
	const [telaAprovar, setTelaAprovar] = useState<Boolean>(props.aprovar);
	const [saving, setSaving] = useState<boolean>(false);
	const [savingReprovar, setSavingReprovar] = useState<boolean>(false);
	const [savingDesfazer, setSavingDesfazer] = useState<boolean>(false);
	const [disableBtnDesfazer, setDisableBtnDesfazer] = useState<boolean>(true);
	const [msg, setMsg] = useState<string>('');
	const [justificativa, setJustificativa] = useState<string>('');
	const [acao, setAcao] = useState<string>(telaAprovar ? "Aprovação" : "Reprovação");
	const inputRef = useRef<HTMLInputElement>(null);
	const disabledBtns = saving || savingReprovar || savingDesfazer;

	useEffect(() => {
		if (props.aprovar) {
			aprovar(props);
		} else {
			setShowDialog(true);
		}

		setFoco();
	}, [])

	const setFoco = () => {
		const timer = setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 500);
		return () => clearTimeout(timer);
	}

	const aprovar = (props: Props) => {
		setAcao("Aprovação");
		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/pedidos/extras/cadastro/aprovar/solicitacao',
			data: {
				registros: [{
					id: props.idRegistro
				}]
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setSaving,
			beforeMsgSuccessTrue: function (response: any) {
				setDisableBtnDesfazer(false);
				setMsg("<b>Sucesso ao aprovar o pedido extra " + props.idRegistro + "</b>");
			},
			beforeMsgSuccessFalse: function (response: any) {
				if (response.registro && response.registro.situacao == 7) {
					setDisableBtnDesfazer(false);
				}
				else setDisableBtnDesfazer(true);

				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}

				setMsg(msg);
			},
			beforeMsgSuccessFailure: function (response: any) {
				if (response.registro && response.registro.situacao == 7) {
					setDisableBtnDesfazer(false);
				}
				else setDisableBtnDesfazer(true);

				let msg = response.msg ? response.msg : "Falha ao aprovar o pedido extra";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}

				setMsg(msg);
			}
		});
	}

	const reprovar = (props: Props) => {
		setAcao("Reprovação");
		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/pedidos/extras/cadastro/rejeitar/solicitacao',
			data: {
				registros: [{
					id: props.idRegistro,
					justificativaReprovacao: justificativa
				}]
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setSavingReprovar,
			beforeMsgSuccessTrue: function (response: any) {
				setDisableBtnDesfazer(false);
				setMsg("<b>Sucesso ao reprovar o pedido extra " + props.idRegistro + "</b>");
			},
			beforeMsgSuccessFalse: function (response: any) {
				if (response.registro && response.registro.situacao == 8) {
					setDisableBtnDesfazer(false);
				}
				else setDisableBtnDesfazer(true);

				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						if (erro.msg == null) continue;

						if (msg != "") msg += "<br/>"
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}

				setMsg(msg);
			},
			beforeMsgSuccessFailure: function (response: any) {
				if (response.registro && response.registro.situacao == 8) {
					setDisableBtnDesfazer(false);
				}
				else setDisableBtnDesfazer(true);

				let msg = response.msg ? response.msg : "Falha ao reprovar o pedido extra";
				if (response.errors) {
					for (const erro of response.errors) {
						if (erro.msg == null) continue;

						if (msg != "") msg += "<br/>";
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}

				setMsg(msg);
			}
		});
	}

	const desfazer = (props: Props) => {
		ajax({
			url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/compras/pedidos/extras/cadastro/desfazer/acao',
			data: {
				registro: {
					id: props.idRegistro
				}
			},
			headers: [{
				key: 'token',
				value: props.idToken
			}],
			fnSetShowLoading: setSavingDesfazer,
			beforeMsgSuccessTrue: function (response: any) {
				setDisableBtnDesfazer(true);
				setMsg("<b>Sucesso ao desfazer " + acao + " do pedido extra " + props.idRegistro + "</b>");
			},
			beforeMsgSuccessFalse: function (response: any) {
				let msg = response.msg ? response.msg : "";
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}

				setMsg(msg);
			},
			beforeMsgSuccessFailure: function (response: any) {
				let msg = response.msg ? response.msg : ("Falha ao desfazer " + acao + " do pedido extra");
				if (response.errors) {
					for (const erro of response.errors) {
						msg += erro.msg.indexOf("Unauthorized") !== -1 ? "Usuário não autorizado" : erro.msg;
					}
				}

				if (response.msgDetalhe) {
					if (msg != "") msg += "<br/>" + response.msgDetalhe;
				}
				setMsg(msg);
			}
		});
	}

	return (
		<IonPage>
			<IonContent>

				<Dialog open={showDialog} onClose={() => { }}>
					<DialogTitle>{telaAprovar ? <>Aprovando a compra... <CircularProgress size={24} /></> : ("Justifique sua reprovação do pedido extra " + props.idRegistro)}</DialogTitle>

					<DialogContent hidden={telaAprovar == true}>
						<TextField
							required
							autoFocus
							inputRef={inputRef}
							margin="dense"
							id="multiline-textarea"
							label="Digite sua justificativa"
							multiline
							rows={4}
							value={justificativa}
							onChange={(e) => {
								setJustificativa(e.target.value);
							}}
							fullWidth
						/>
					</DialogContent>

					<DialogActions hidden={telaAprovar == true}>
						<Button
							disabled={disabledBtns}
							onClick={() => {
								reprovar(props);
							}}
							color="primary"
						>
							{savingReprovar ? <CircularProgress size={24} /> : 'Reprovar'}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={msg != null && msg != ""}>
					<DialogTitle>Aviso</DialogTitle>

					<DialogContent>
						<div dangerouslySetInnerHTML={{ __html: msg }} />
					</DialogContent>

					<DialogActions>

						<Button disabled={disabledBtns} hidden={disableBtnDesfazer} onClick={() => desfazer(props)} color="primary">
							{savingDesfazer ? <CircularProgress size={24} /> : ('Desfazer ' + acao)}
						</Button>

						<Button disabled={disabledBtns} hidden={!disableBtnDesfazer ? true : false} onClick={() => aprovar(props)} color="primary">
							{saving ? <CircularProgress size={24} /> : 'Aprovar'}
						</Button>

						<Button
							hidden={!disableBtnDesfazer ? true : false}
							disabled={disabledBtns}
							onClick={() => {
								if (justificativa != null && justificativa != "") {
									reprovar(props);
								}
								else {
									setMsg("");
									setTelaAprovar(false);
								}
							}}
							color="primary"
						>
							{savingReprovar ? <CircularProgress size={24} /> : 'Reprovar'}
						</Button>

						<Button hidden={telaAprovar ? true : false} onClick={() => setMsg("")} color="primary">
							Fechar
						</Button>

					</DialogActions>
				</Dialog>

			</IonContent>
		</IonPage>
	)
}

export default AprovacaoPedidoExtra