import React, { useState, useEffect, useRef } from 'react';
import {
  IonModal, IonContent, IonPage, IonButton, IonImg, IonItem, IonLabel, IonCheckbox, IonToast, IonSpinner, IonTitle, IonCard
} from '@ionic/react';
import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Grid, FormControl, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import './Login.css';
import ConfigProvider from '../../assets/utils/configProvider';
import ajax from '../../assets/utils/ajax';
import WelcomeModal from './WelcomeModal';
import EsqueciSenhaModal from './EsqueciSenhaModal';
import { useDispatch } from 'react-redux';
import { createWebSocket } from "../../assets/utils/redux/actions";

const { SecureStoragePlugin } = Plugins;
const logomarca = require('../../assets/img/logomarca.svg');
const logomarcaG3 = require('../../assets/img/logomarca-horizontal-g3.png');

const CssOutlinedInput = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      color: 'white'
    },
    '& .MuiSvgIcon-root': {
      color: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FEFEFE',
      // height: 70,
      maxWidth: 450,
      borderRadius: 20,
      borderWidth: 3
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FEFEFE',
      // height: 70,
      maxWidth: 450,
      borderRadius: 20,
      borderWidth: 3
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FEFEFE',
      // height: 70,
      maxWidth: 450,
      borderRadius: 20,
      borderWidth: 3
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E53B3E',
      // height: 70,
      maxWidth: 450,
      borderRadius: 20,
      borderWidth: 3
    },
    '&.Mui-error .MuiInputBase-input': {
      color: '#FFFFFF',
    },
    '&.Mui-error .MuiOutlinedInput-inputAdornedEnd': {
      color: '#E53B3E'
    },
    '&.Mui-error .MuiSvgIcon-root': {
      color: '#E53B3E'
    },
    "&$selected": {       // this is to refer to the prop provided by M-UI
      backgroundColor: "black", // updated backgroundColor
    },
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
    '&::placeholder': {
      color: "#FFFFFF",
      opacity: "0.8"
    }
  },
})(OutlinedInput);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    margin: {
      margin: theme.spacing(1),
      // height: 70
    },
    form: {
      maxWidth: 450
    },
  }),
);

interface ViewportProps {
  onSetIsLogged: any;
  user?: any;
  welcome?: any;
  token?: any;
  typeWelcome?: any;
  email?: any;
}

const Login: React.FC<ViewportProps> = (props) => {
  const classes = useStyles();

  const [showAlert, setShowAlert] = useState<object>();
  const [lembrarAcesso, setLembrarAcesso] = useState<boolean>();
  const [showPassword, setShowPassword] = useState<boolean>();
  const [valueUsuario, setValueUsuario] = useState<string>();
  const [valuePassword, setValuePassword] = useState<string>();
  const [errorUsuario, setErrorUsuario] = useState<boolean>();
  const [errorPassword, setErrorPassword] = useState<boolean>();
  const [showLoading, setShowLoading] = useState<boolean>();
  const [showLoadingSenha, setShowLoadingSenha] = useState<boolean>();
  const [showModalWelcome, setShowModalWelcome] = useState<boolean>(false);
  const [showModalEsqueciSenha, setShowModalEsqueciSenha] = useState<boolean>(false);
  const dispatch = useDispatch();

  let refPassword = useRef<HTMLInputElement>(null)
  let refUser = useRef<HTMLInputElement>(null)

  useEffect(() => {
    dispatch(createWebSocket(false));

    async function getSession() {
      try {
        if (props.email && !props.welcome) {
          setValueUsuario(props.email);
          setValuePassword("");
          setTimeout(() => { refPassword?.current?.focus() }, 500)
        } else {
          await SecureStoragePlugin.remove({ key: "idEmpresa" });
          await SecureStoragePlugin.remove({ key: "login" });
          await SecureStoragePlugin.remove({ key: "token" });
          await SecureStoragePlugin.remove({ key: "nomeUsuario" });

          const user = await SecureStoragePlugin.get({ key: 'userL' })
            .then((value: any) => {
              return value;
            })
            .catch((error: any) => {
              return null;
            });

          const pass = await SecureStoragePlugin.get({ key: 'passL' })
            .then((value: any) => {
              return value;
            })
            .catch((error: any) => {
              return null;
            });

          const tok = await SecureStoragePlugin.get({ key: 'token' })
            .then((value: any) => {
              return value;
            })
            .catch((error: any) => {
              return null;
            });

          if ((user !== null && user.value !== "" && user.value !== null)
            && (pass !== null && pass.value !== "" && pass.value !== null)) {
            setValueUsuario(user.value);
            setValuePassword(pass.value);
            setLembrarAcesso(true);

            if (tok !== null && tok.value !== "" && tok.value !== null)
              submitLogin(new Event("submit"), { user: user.value, pass: pass.value });
          }
        }
      } catch (error) {
      }
    }
    getSession();
  }, []);

  useEffect(() => {
    if (props.welcome && props.token) {
      setShowModalWelcome(true)
    }
    if (props.user) {
      setValueUsuario(props.user);
      if (!props.email) {
        if (!props.welcome) {
          setTimeout(() => { refPassword?.current?.focus() }, 500)
        }
      }
    } else {
      if (!props.email) {
        if (!props.welcome) {
          setTimeout(() => { refUser?.current?.focus() }, 500)
        }
      }
    }
  }, [props.user])

  const fnLembrarAcesso = (checked: boolean) => {
    setLembrarAcesso(checked);
  }

  const submitLogin = (event: any, data: any) => {
    event.preventDefault();

    let user = valueUsuario;
    let pass = valuePassword;
    if (data) {
      user = data.user;
      pass = data.pass;
    }

    let optionsNotification = {
      isOpen: true,
      duration: 3000,
      color: 'danger',
      header: 'Aviso',
      message: '',
      buttons: ['OK'],
      onDidDismiss: () => setShowAlert({ isOpen: false })
    }

    if (!user || user.trim() === "") {
      setErrorUsuario(true);
      optionsNotification.message = "Por favor, informe um E-mail.";
      setShowAlert(optionsNotification);
      return false;
    }
    else {
      if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(user.toString()))) {
        setErrorUsuario(true);
        optionsNotification.message = "O usuário deve ser um email no formato: email@email.com";
        setShowAlert(optionsNotification);
        return false;
      }
      else {
        setErrorUsuario(false);
      }
    }

    if (!pass || pass.trim() === "") {
      setErrorPassword(true);
      optionsNotification.message = "Por favor, informe uma senha.";
      setShowAlert(optionsNotification);
      return false;
    } else {
      if (/[^A-Za-z\w  !@#$%¨^&*_+=?;.,{}\(\)\[\]\-]/g.test(pass.toString())) {
        setErrorPassword(true);
        optionsNotification.message = "A senha contém caracteres não permitidos";
        setShowAlert(optionsNotification);
        return false;
      }
      else {
        setErrorPassword(false);
      }
    }

    ajax({
      url: new ConfigProvider().getConfig("baseURLG3Auth") + '/auth/authPortal',
      data: {
        login: user,
        senha: pass,
        tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
      },
      fnSetShowLoading: setShowLoading,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {

        if (lembrarAcesso) {
          SecureStoragePlugin.set({ key: "passL", value: "" + pass });
        } else {
          SecureStoragePlugin.remove({ key: "passL" });
        }
        SecureStoragePlugin.set({ key: "userL", value: "" + user });
        SecureStoragePlugin.set({ key: "idEmpresa", value: "1" });
        SecureStoragePlugin.set({ key: "login", value: "" + user });
        SecureStoragePlugin.set({ key: "token", value: response.token });
        SecureStoragePlugin.set({ key: "nomeUsuario", value: response.nomeUsuario });

        ajax({
          url: new ConfigProvider().getConfig("baseURLG3Auth") + '/token/valida/portal',
          data: {
            token: response.token,
            tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
          },
          fnSetShowLoading: setShowLoading,
          fnSetShowAlert: setShowAlert,
          beforeMsgSuccessTrue: function (resposta: any) {
            SecureStoragePlugin.set({ key: "idUsuario", value: resposta.idUsuario.toString() });

            props.onSetIsLogged(true);

            if (response.responseCallback) {
              response.responseCallback()
            }
          },
          beforeMsgSuccessFalse: function (resposta: any) {
            SecureStoragePlugin.set({ key: "idUsuario", value: resposta.idUsuario.toString() });

            props.onSetIsLogged(true);

            if (response.responseCallback) {
              response.responseCallback()
            }
          }
        });
      },
      beforeMsgSuccessFalse: function (response: any) {
        if (response.errors.length > 0) {
          if (response.errors[0].msg == "Usuario não tem senha cadastrada, para criar uma, acesse o link de definição de senha enviado para seu e-mail!") {
            handleClickEsqueciSenha()
          }
        }
      }
    });
  }

  const handleClickEsqueciSenha = () => {
    ajax({
      url: new ConfigProvider().getConfig("baseURLG3WSCotacao") + '/public/portal/usuarios/cadastro/update/senha',
      data: {
        email: valueUsuario,
        tipoAplicacao: new ConfigProvider().getConfig("tipoAplicacao")
      },
      fnSetShowLoading: setShowLoadingSenha,
      fnSetShowAlert: setShowAlert,
      beforeMsgSuccessTrue: function (response: any) {
        if (response.success == true) {
          setShowModalEsqueciSenha(true)
        }

        if (response.responseCallback) {
          response.responseCallback()
        }

      }
    });
  };

  return (
    <IonPage>
      <IonContent className="usuario">
        <div className="containerAux">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <IonCard className="tipoAplicacaoCard">
                <IonTitle className="tipoAplicacao" color="primary" size="large">Pedidos Extras</IonTitle>
              </IonCard>
              <IonImg className="logomarca" src={logomarca} />
            </Grid>
          </Grid>

          <form>
            <FormControl
              fullWidth
              className={classes.form}
              variant="outlined"
              required
            >
              <CssOutlinedInput
                className={classes.margin}
                value={valueUsuario !== undefined ? valueUsuario : ''}
                placeholder="E-mail"
                autoComplete="false"
                autoFocus={true}
                type='text'
                id="custom-css-outlined-input"
                error={errorUsuario}
                required={true}
                inputRef={refUser}
                onChange={(e) => {
                  if (e.target.value.trim() !== "") {
                    setErrorUsuario(false);
                  } else {
                    setErrorUsuario(true);
                  }
                  setValueUsuario(e.target.value);
                }}
              />

              <CssOutlinedInput
                className={classes.margin}
                value={valuePassword !== undefined ? valuePassword : ''}
                placeholder="Senha"
                type={showPassword ? 'text' : 'password'}
                autoComplete="false"
                id="custom-css-outlined-input"
                error={errorPassword}
                required={true}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value.trim() !== "") {
                    setErrorPassword(false);
                  } else {
                    setErrorPassword(true);
                  }
                  setValuePassword(value);
                }}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    submitLogin(event, null);
                  }
                }}
                inputRef={refPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="deixar senha visível"
                      tabIndex={"-1"}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <IonItem className="item">
                <IonLabel>Lembrar meu usuário</IonLabel>
                <IonCheckbox color="primary" checked={lembrarAcesso} slot="start"
                  onIonChange={(e) => fnLembrarAcesso(e.detail.checked)}
                />
              </IonItem>

              <div className={classes.margin}>
                <IonButton
                  className="btnEntrar"
                  color="medium"
                  onClick={(event) => submitLogin(event, null)}
                  disabled={showLoading}
                >
                  {!showLoading ? "ACESSAR" : <IonSpinner name="crescent" />}
                </IonButton>

                <IonButton
                  className="btnEntrar"
                  color="medium"
                  onClick={() => {
                    if (valueUsuario) {
                      handleClickEsqueciSenha();
                    } else {
                      setErrorUsuario(true)
                      let optionsNotification = {
                        isOpen: true,
                        duration: 3000,
                        color: 'danger',
                        header: 'Aviso',
                        message: '',
                        buttons: ['OK'],
                        onDidDismiss: () => setShowAlert({ isOpen: false })
                      }
                      optionsNotification.message = "Por favor, informe um E-mail.";
                      setShowAlert(optionsNotification);
                    }
                  }}
                >
                  {!showLoadingSenha ? "ESQUECI MINHA SENHA" : <IonSpinner name="crescent" />}
                </IonButton>
              </div>
            </FormControl>
          </form>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <IonItem className="item">
                <IonImg className="logomarcaG3" src={logomarcaG3} />
              </IonItem>
            </Grid>
          </Grid>
        </div>
      </IonContent>
      <IonModal backdropDismiss={false} isOpen={showModalWelcome} swipeToClose={false}>
        <WelcomeModal typeWelcome={props.typeWelcome} onSetShowModal={setShowModalWelcome} user={props.user} token={props.token} email={props.email} />
      </IonModal>
      <IonModal backdropDismiss={false} isOpen={showModalEsqueciSenha} swipeToClose={false}>
        <EsqueciSenhaModal onSetShowModal={setShowModalEsqueciSenha} user={valueUsuario} />
      </IonModal>
      <IonToast
        isOpen={false}
        cssClass='my-custom-class'
        mode='ios'
        {...showAlert}
      />
    </IonPage>
  );
};

export default Login;
